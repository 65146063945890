<template>
  <v-app class="page_bg">


    <!-- Code for BISC start -->
    <div class="main_div" v-if="is_bisc_display">
        <BISC :invoiceno ="invoiceno"/>
    </div>
    <!-- Code for BISC end -->


    <div class="main_div" v-if="isMainDiv">
      <v-card 
        class="mx-auto"
        max-width="450"
        style="margin-top:100px;border-radius:15px"
      >

      <!-- Show response message popup/dialog box -->
      <v-snackbar
        v-model="snackbar"
        :color="color"
        :multi-line="mode === 'multi-line'"
        :timeout="timeout"
        :top="true"
        :vertical="mode === 'vertical'"
        rounded="pill"
      >
        {{ popup_text }}
      <v-btn text @click="snackbar = false" style="color:#ffffff;">Close</v-btn>
      </v-snackbar>

      <!-- Logo of the merchant -->
      <div class="avatar_div">
        <v-card-actions class="justify-center">
          <v-avatar size="100px" style="margin-top:-56px;" class="justify-center avatar_img" >
          <v-img
              :src="merchant_logo"
              alt="merchant logo"
              title="merchant logo"
              style="background-color:#fff;width:100px"
              contain
          ></v-img>
          </v-avatar>
        </v-card-actions>
      </div>
      
      <!-- Customer name and sign out section -->
      <div class="name_logout" v-if="myaccount">
        <v-container style="margin-top:-20px">
          <v-row style="height:30px;">
            <v-col md="12" sm="12" xs="12">
              <p class="client_name_andLogout">{{customer_name}} <span style="color:#0b4262">|</span>
                 <span @click="logout_user"> <v-btn 
                  text
                  elevation="0"
                  class="logout_btn"
                 >Logout</v-btn>
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Payment ,client name,Taka amount,order_id section-->
      
      <div class="pay_amount_main_div" v-if="normal_user">
        <v-container style="margin-top:-20px;">
          <v-row :style="{ height: paymentToHeight + 'px', marginTop:'-10px' }">
            <v-col md="6" sm="6" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="6" sm="6" xs="12" :style="{borderLeft: '2px dotted #000000', height: leftBorderHeight +'px', marginTop: '22px'}">
              <p class="payment_amount">{{sp_amount_display}} &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>


       <!-- When surcharge active -->
       <div class="pay_amount_main_div" v-if="tdr_visibility">

        <!-- Design - 1 -->
        <v-container style="margin-top:-20px;">
          <v-row style="margin-top:-10px;">

            <v-col md="6" sm="6" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="6" sm="6" xs="12" class="border_left2">

              <p class="tdr_amount_label">
                Amount: ৳<span class="amount_label">{{sp_amount_display}}</span>
              </p>

              <p class="additional_fees">
                 Additional Fees: ৳<span class="amount_label">{{additional_fees_amt}}</span>
                 <v-icon 
                  class="tdrInfoBtn"
                  @click="showAdditionalFeesPopUp()"
                  >
                   mdi-help-circle-outline
                  </v-icon>
              </p>

              
              <p class="payment_amount">Payable: {{payable_amt}} 
                &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>

            </v-col>
          </v-row>
        </v-container>

      </div>


      <!-- Tabs or Nav section start-->
      <div style="background-color:#fff;margin:5px">
        <v-card elevation="0">
          
          <v-tabs
            v-model="active_tab"
            background-color="#fff"
            dark
            centered
            icons-and-text
            height="55px"
          >
            <v-tabs-slider></v-tabs-slider>
            
            <v-tab href="#cards" class="tabs_text_style" id="card_style" @click="show_cards(1); tabIndex(0)"
             v-if="isCard" :style="dwidth">CARDS
              <v-icon size="22px" style="color:#fff">mdi-credit-card-multiple</v-icon>
            </v-tab>

             <v-tab href="#mfs" class="tabs_text_style" id="mbanking_style" @click="show_mbanking(); tabIndex(1)"
             v-if="isMbanking" :style="dwidth">mWALLET
              <v-icon  size="22px" style="color:#fff">mdi-cellphone</v-icon>
            </v-tab>
            
            <v-tab href="#ibanking" class="tabs_text_style" id="ibanking_style" @click="show_ibanking(); tabIndex(2)"
             v-if="isIBanking" :style="dwidth">iBANKING 
              <v-icon  size="22px" style="color:#fff">mdi-bank</v-icon>
            </v-tab>
            
          
            <v-tab href="#account" class="tabs_text_style" id="account_style"  @click="show_account(); tabIndex(3)" 
            v-if="no_login" :style="dwidth">LOGIN
              <v-icon  size="22px" style="color:#fff">mdi-account</v-icon>
            </v-tab>

            <v-tab href="#account" class="tabs_text_style" id="account_style"  @click="show_account(); tabIndex(3)"
             v-if="myaccount" :style="dwidth">PROFILE
              <v-icon  size="22px" style="color:#fff">mdi-account-check</v-icon> 
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="active_bar">
          
          <!-- Card tab content -->
          <v-tabs-items v-if="card_tab">
             <v-container  grid-list-xl justify-space-around wrap fluid class="containerStyle">
                
                 <v-layout wrap class="rowStyle">
                  <v-flex xs4 md4 sm4 v-for="(cards,index) in cards_gateways" :key="index">
                    <v-hover style="max-height:67px !important" v-slot:default="{ hover }">
                      <v-img
                        :src ="image_base_url+ cards.hover_image"
                        :alt = "cards.slug"
                        max-width = "100%"
                        @click="goCardGateway(cards.slug,cards.gateway_id,cards.gateway_type_id)"
                        :class=" hover ? 'hover_img': 'normal_img'"
                        :title ="cards.method_name"
                      >
                       <v-img
                        :src ="image_base_url+ cards.image"
                        v-if="hover"
                        max-width = "100%"
                        :title ="cards.method_name"
                      >
                      </v-img>

                       <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ cards.image"
                          v-if="cards.gateway_id==cards_selected_gateway_iddd"
                          max-width = "100%"
                        >
                       </v-img>

                      </v-img>
                     
                    </v-hover>
                  </v-flex>

                </v-layout>
              </v-container>
          </v-tabs-items>

          <v-tabs-items v-if="cardSkeletonLoader">
            <v-container>
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                
              </v-row>
            </v-container>
          </v-tabs-items>

          <!-- Save card section form start -->
           <v-tabs-items v-if="saved_card_tab1" >
              <v-container>
                <div style="height:41px;" v-if="hidecardmenu">
                  <ul style="list-style-type: none;">

                   <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/visa.png')"
                        alt="visa card"
                        title="VISA"
                        width="30"
                        v-if="visaDeactive"
                      >
                      </v-img>

                    <v-img
                        :src="require('./assets/logos/ad_logo/visa_h.png')"
                        alt="visa card "
                        title="VISA"
                        width="30"
                        v-if="visaActive"
                      >
                    </v-img>

                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/master.png')"
                        alt="master card"
                        title="Mastercard"
                        width="30"
                        style="margin-left:5px"
                        v-if="masterCardDeactive"
                      >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/master_h.png')"
                        alt="master card"
                        title="Mastercard"
                        width="30"
                        style="margin-left:5px"
                        v-if="masterCardActive"
                      >
                      </v-img>
                    </li>

                   

                    <!-- <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/amex.png')"
                          alt="american exprees"
                          title ="American Express"
                          width="30"
                          style="margin-left:5px"
                          v-if="amexDeactive"
                      >
                      </v-img>

                      <v-img
                      :src="require('./assets/logos/ad_logo/amex_h.png')"
                        alt="american exprees"
                        title ="American Express"
                        width="30"
                        style="margin-left:5px"
                        v-if="amexActive"
                      >
                      </v-img>
                    </li> -->

                    <!-- <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay.png')"
                          alt="unionpay Deactive"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayDeactive"
                        >
                        </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay_h.png')"
                          alt="unionpay Active"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayActive"
                        >
                        </v-img>
                    </li> -->

                    <!-- <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/diners.png')"
                          alt="diners-club"
                          width="30"
                          style="margin-left:5px"
                          v-if="dinersDeactive"
                        >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/diners_h.png')"
                        alt="diners-club"
                        width="30"
                        style="margin-left:5px"
                        v-if="dinersActive"
                        >
                      </v-img>
                    </li> -->

                    <li style="float:left;margin-left:8px;margin-top:0px;">
                      <v-btn text x-small color="primary" class="other_btn" @click="othersCard(); tabIndex(4)">
                        Other Cards
                      </v-btn>
                    </li>

                  </ul>
                </div> 

                 <v-row v-if="other_cards_display" style="min-height:230px;">
                   <!-- <v-col md="4" sm="4" xs="4" @click = "goDirectCardsGateWay('nexus-pay')">
                    <v-hover>
                      <v-img
                        slot-scope = "{ hover }"
                        v-if = "hover"
                        max-width = "140px"
                        :src="require('./assets/logos/nexus_debit.png')"
                        alt = "Nexus Debit"
                        style="cursor:pointer;border-bottom:4px solid #0F9750;border-radius:4px"
                       
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="require('./assets/logos/nexus_debit_hover.png')"
                        max-width = "140px"
                      >
                      </v-img>
                    </v-hover>
                   </v-col> -->

                    <!-- <v-col md="4" sm="4" xs="4">
                    <v-hover>
                      <v-img
                        slot-scope = "{ hover }"
                        v-if = "hover"
                        max-width = "140px"
                        :src="require('./assets/logos/qcash_h.png')"
                        alt = "Qcash"
                        style="cursor:pointer;border-bottom:4px solid #0F9750;border-radius:4px"
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="require('./assets/logos/qcash.png')"
                        max-width = "140px"
                      >
                      </v-img>
                    </v-hover>
                   </v-col> -->

                    <v-container grid-list-xl  wrap fluid class="mobileBankingSec" >

                      <v-layout wrap class="rowStyle">

                        <v-flex xs4 md4 sm4 v-for="(s_other_cards,index) in selected_other_card_list" :key="index">

                          <v-hover style="max-height:67px !important" v-slot:default="{ hover}">
                            <v-img
                              :src ="image_base_url+ s_other_cards.hover_image"
                              :alt = "s_other_cards.slug"
                              max-width = "100%"
                              @click="goCardGateway(s_other_cards.slug,s_other_cards.gateway_id,s_other_cards.gateway_type_id)"
                              :class=" hover ? 'hover_img': 'normal_img'"
                              :title = "s_other_cards.method_name"
                            >
                              <v-img
                                :src ="image_base_url+ s_other_cards.image"
                                v-if="hover"
                                max-width = "100%"
                                :title = "s_other_cards.method_name"
                              >
                            </v-img>

                              <!-- Selected gateway icon -->
                              <v-img
                                :src ="image_base_url+ s_other_cards.image"
                                v-if="s_other_cards.gateway_id==cards_selected_gateway_iddd"
                                max-width = "100%"
                              >
                            </v-img>

                            </v-img>

                          </v-hover>
                        

                        </v-flex>

                      </v-layout>

                    </v-container>

                   
                 </v-row>
                  
                  <v-row style="min-height:230px;" v-if="saved_card_section">
                   <v-col md="12" sm="12" xs="12">
                       <SavedCardList ref="form"/>
                   </v-col>
                 </v-row>
                

                 <v-row style="margin-top:-10px;margin-left:1px;margin-right:1px;height:60px;background-color:rgb(243, 243, 243);" v-if="is_mask_card_enable">
                    <v-col md="12" sm="12" xs="12">

                      <p class="listItems" style="color: gray;font-weight: bold;margin-top:6px">
                        {{this.mask_card_number}}
                      </p>

                    </v-col>

                    <!-- <v-col md="4" sm="4" xs="4">
                         <v-img :src="mask_card_icon" width="50%" height="auto"></v-img>
                    </v-col>  -->

                    <!-- <v-col md="12" sm="12" xs="12"  style="height:60px;">

                    <v-list>
                      <v-list-item style="margin-top:-30px;background-color:#ececec;height: 65px;">

                        <v-list-item-content>
                          <v-list-item-title> {{this.mask_card_number}} </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-avatar tile>
                            <v-img :src="mask_card_icon" width="100%" height="auto"></v-img>
                          </v-list-item-avatar>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                  </v-col> -->

                   
                 </v-row>

                <v-form ref="form_cards" style="margin-top:-10px" v-model="isFormValid" v-if="hideCard_div" autocomplete="off">
                  <v-row style="height:60px" v-if="is_cof_form_enable">
                    <v-col :md="colWidth" :sm="colWidth" xs="12">
                      <v-text-field
                        v-model="editedItem.card_number"
                        :rules="CardNumberRules" 
                        label="Card Number"
                        oninput = "this.value = this.value.replace(/[^0-9 ]/g, '').replace(/(\..*)\./g, '$1');"
                        required
                        outlined
                        dense
                        maxlength="19"
                        class="cc-number-input"
                        v-mask="'#### #### #### ####'"
                        @blur = CheckCardOnFileTdr()
                        autocomplete="name"
                        prepend-inner-icon="mdi-credit-card"
                      >
                      </v-text-field> 
                    </v-col>
                   
                    <!-- <v-col md="6" sm="6" xs="12" v-if="is_emi_visible">
                      <v-select
                        v-model="editedItem.emi"
                        :items="emi_options"
                        item-text="option_val"
                        item-value="select_val"
                        label="Avail EMI"
                        outlined
                        dense
                        style="height:40px;"
                      >
                      <template slot='selection'>
                          <span class="emiDisplaytext">{{editedItem.emi}}</span>
                      </template>

                      </v-select>
                    </v-col> -->

                  </v-row>
                  
                  <v-row style="height:60px" v-if="is_cof_form_enable">

                    <v-col md="6" sm="6" xs="12">
                        <v-text-field
                          v-model="editedItem.mm_yy"
                          label="MM/YY"
                          :rules="mmyyRule"
                          required
                          outlined
                          oninput="this.value = this.value.replace(/[^0-9/]/g, '').replace(/(\..*)\./g, '$1');"
                          maxlength="5"
                          dense
                          autocomplete="name"
                          @keyup="checkMonthYearValidity" 
                          prepend-inner-icon="mdi-calendar"
                          color="red"
                        >
                        </v-text-field> 
                    </v-col>


                    <v-col md="6" sm="6" xs="12" >
                        <v-text-field
                          v-model="editedItem.cvc_cvv"
                          label="CVC/CVV"
                          :rules="cvc_cvvRules" 
                          autocomplete="off"
                          maxlength="4"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          required
                          outlined
                          dense 
                          type="password"
                          id="cvc_cvv"
                          prepend-inner-icon="mdi-lock"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <v-row style="height:60px" v-if="is_cof_form_enable">
                    <v-col md="12" sm="12" xs="12" >
                        <v-text-field
                          v-model="editedItem.card_holder_name"
                          label="Card Holder Name"
                          :rules="[v => !!v || 'Card holder name is required']"
                          oninput="this.value = this.value.replace(/[^a-zA-Z _.-]/g, '').replace(/(\..*)\./g, '$1');"
                          required
                          outlined
                          dense
                          autocomplete="name"
                          id="card_holder_name"
                          prepend-inner-icon="mdi-account"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <div style="background-color:#fff;margin-top:7px">
                    <v-row v-if="isCheckLoginEnable">
                      <v-col>
                        <v-checkbox v-model="checkbox" style="margin-top:-5px;height:30px;padding-left:5px;" @click="CheckLogin()" id="check_login_checkbox_id">
                          <template v-slot:label>
                            <div style="color:rgb(63, 61, 61)">
                              Save card for fast checkout
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>

                    </v-row>

                    
                    <!-- Create account during COF -->

                    <div v-if="showCreateAccountForm">
                      
                      <p style='padding-left: 5px;padding-top: 5px;font-weight: bold;font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;'>
                        Create an account
                      </p>

                      <v-form @submit.prevent="COFregister">

                      <v-row style="padding-left:5px;padding-right:5px;margin-top:-20px">
                        <v-col md="12" sm="12" xs="12" style="height:64px">
                            <VuePhoneNumberInput
                              v-model="cof_signup.mobile_number"
                              default-country-code="BD"
                              color="#0F9750"
                              valid-color="#0F9750"
                              error-color="#FF0000"
                              :translations="translations"
                              clearable 
                              class="mb-2"
                              required
                              @update="onUpdate"
                              no-example
                            />  
                        </v-col>
                      </v-row>

                      <v-row style="padding-left:5px;padding-right:5px;">
                        <v-col md="12" sm="12" xs="12" style="height:60px">
                          <v-text-field
                              v-model="cof_signup.password"
                              name="password"
                              :value="myPass"
                              label="Set a password"
                              :rules="[
                                () => !!cof_signup.password || 'This field is required',
                                () => !!cof_signup.password && cof_signup.password.length >= 6 || 'Password must at less than 6 characters',
                              ]"
                              prepend-inner-icon="mdi-key"
                              :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                              @click:append="() => (value = !value)"
                              :type="value ? 'password' : 'text'"
                              outlined
                              required
                              autofocus
                              dense
                              autocomplete = "off"
                              @keydown.enter.prevent="COFregister()"
                            >
                            </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row style="padding-left:5px;padding-right:5px;padding-bottom:7px">
                        <v-col md="12" sm="12" xs="12" style="height:60px;padding:12px">
                          <v-text-field
                              v-model="cof_signup.fullname"
                              :rules="fullnameRules"
                              label="Full name"
                              prepend-inner-icon="mdi-account"
                              oninput="this.value = this.value.replace(/[^a-zA-Z _ . -]/g, '').replace(/(\..*)\./g, '$1');"
                              autocomplete="off"
                              required
                              outlined
                              dense
                              maxlength="50"
                              @keydown.enter.prevent="COFregister()"
                            >
                            </v-text-field> 
                          
                        </v-col>
                      </v-row>
                      </v-form>

                      <v-row justify="center" style="padding-left:5px;padding-right:5px;margin-top:6px;">
                        <v-col md="6" sm="6" xs="6" style="height:65px">
                            <v-btn block color="primary" dark style="margin-top:-8px" @click="COFregister()">Sign Up</v-btn>
                        </v-col>
                      </v-row>

                    </div>

                    
                    <div style="margin-top:30px" v-if="showCOFRegisterOTPForm">
                        
                        <v-row style="padding-left:5px;padding-right:5px;margin-top:-26px;">
                          <v-col>
                            <v-card-subtitle class="justify-center" style="padding-top: 3px; font-size: 15px; margin-left: -9px;font-family:Calibri,Candara, Segoe">
                              An <strong>OTP</strong> was just sent to <span style="font-weight:bold">{{this.cof_user_reg_mobile}}</span>
                            </v-card-subtitle>
                          </v-col>
                        </v-row>

                        <v-row style="padding-left:5px;padding-right:5px;margin-top:-6px;height:80px">

                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                            <v-form>
                              <v-text-field
                                v-model="cof_reg_otp_confirm.one"
                                autocomplete="off"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                outlined
                                maxlength="1"
                                style="font-size:24px"
                                autofocus
                                id="cof_one"
                                class="pin_align"
                              >
                              </v-text-field>
                            </v-form>
                          </v-col>


                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                                <v-text-field
                                  v-model="cof_reg_otp_confirm.two"
                                  autocomplete="off"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  outlined
                                  maxlength="1"
                                  style="font-size:24px"
                                  id="cof_two"
                                  @keyup.delete="OTPReverse('cof_one')"
                                >
                                </v-text-field>
                          </v-col>

                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                                <v-text-field
                                  v-model="cof_reg_otp_confirm.three"
                                  autocomplete="off"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  outlined
                                  maxlength="1"
                                  style="font-size:24px"
                                  id="cof_three"
                                  @keyup.delete="OTPReverse('cof_two')"
                                >
                                </v-text-field>
                          </v-col>

                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                                <v-text-field
                                  v-model="cof_reg_otp_confirm.four"
                                  autocomplete="off"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  outlined
                                  maxlength="1"
                                  style="font-size:24px"
                                  id="cof_four"
                                  @keyup.delete="OTPReverse('cof_three')"
                                >
                                </v-text-field>
                          </v-col>

                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                                <v-text-field
                                  v-model="cof_reg_otp_confirm.five"
                                  autocomplete="off"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  outlined
                                  maxlength="1"
                                  style="font-size:24px"
                                  id="cof_five"
                                  @keyup.delete="OTPReverse('cof_four')"
                                >
                                </v-text-field>
                          </v-col>

                          <v-col md="2" sm="2" xs="2" style="margin-top:-22px">
                                <v-text-field
                                  v-model="cof_reg_otp_confirm.six"
                                  autocomplete="off"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  outlined
                                  maxlength="1"
                                  style="font-size:24px;"
                                  id="cof_six"
                                  @keyup.delete="OTPReverse('cof_five')"
                                  @keyup="COFSubmitOTP()"
                                >
                                </v-text-field>
                          </v-col>
                        
                        </v-row>

                        <v-row justify="center" style="padding-left:5px;padding-right:5px;margin-top:-25px;">
                         
                           <v-col md="6" sm="6" xs="6" style="height:65px">

                            <v-btn text block v-if="dispaly_time_otp" large color="#000" dark style="margin-top:-8px;text-transform: capitalize;">
                              Time Left {{display_min}}:{{display_sec}}
                              <!-- <v-icon left>mdi-alarm</v-icon> -->
                            </v-btn>

                            <v-btn block v-if="display_resend_otp_text" large color="primary" dark @click="COFResendOTP()" 
                              style="text-transform: capitalize;font-size:13px;margin-top:-8px">{{resent_otp_text}}
                            </v-btn>

                          </v-col>

                        </v-row>

                    </div>


                    <!-- Existing customer login screen during cof -->

                    <div v-if="isCOFLoginFormEnable">
                      
                      <p style='padding:10px;font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;text-align: justify;'>
                        You already have an account with us linked to mobile number <span style="font-weight:bold">{{this.customer_trxn_mobile_number}}</span>
                      </p>

                      <v-form @submit.prevent="COFLoginAction">
                        <v-row style="padding-left:5px;padding-right:5px;margin-top:-20px">
                          <v-col md="12" sm="12" xs="12" style="height:64px">
                              <VuePhoneNumberInput
                                v-model="cof_login_mobile_number"
                                default-country-code="BD"
                                color="#0F9750"
                                valid-color="#0F9750"
                                error-color="#FF0000"
                                :translations="translations"
                                clearable 
                                class="mb-2"
                                required
                                @update="onUpdate"
                                no-example
                                @keydown.enter.prevent="COFLoginAction()"
                              />  
                          </v-col>
                        </v-row>

                        <v-row style="padding-left:5px;padding-right:5px;">
                          <v-col md="12" sm="12" xs="12" style="height:64px">
                          
                              <v-text-field
                                  v-model="cof_login_pin"
                                  name="password"
                                  :value="myPass"
                                  label="Password"
                                  :rules="[COFLoginPasswordRules.required, COFLoginPasswordRules.min]"
                                  prepend-inner-icon="mdi-key"
                                  :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="() => (value = !value)"
                                  :type="value ? 'password' : 'text'"
                                  outlined
                                  required
                                  dense
                                  autocomplete = "off"
                                  @keydown.enter.prevent="COFLoginAction()"
                                  autofocus
                                >
                                </v-text-field>
                            
                          </v-col>
                        </v-row>
                    </v-form>

                      <v-row justify="center" style="padding-left:5px;padding-right:5px;margin-top:10px;">
                        <v-col md="6" sm="6" xs="6" style="height:65px">
                            <v-btn block color="primary" dark style="margin-top:-8px" @click="COFLoginAction()">Login</v-btn>
                        </v-col>
                      </v-row>

                    </div>

                    <!-- Active account div -->
                    <div v-if="AccountActiveDeactiveDiv">
                      
                      <p style='padding:10px;font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;text-align: justify;'>
                        You already have an account linked to <span style="font-weight:bold">{{this.cof_active_account_mobile}}</span>
                        but you didn't verify. We again send an OTP to <span style="font-weight:bold">verify your Mobile Number.</span>
                      </p>

                      <v-form>
                        <v-row style="padding-left:5px;padding-right:5px;margin-top:-20px">

                          <v-col md="12" sm="12" xs="12">
                            <v-text-field
                              v-model="cof_active_account_otp"
                              :rules="[SignUpPasswordRules.required, SignUpPasswordRules.min]"
                              label="Enter OTP"
                              required
                              outlined
                              autocomplete="off"
                              autofocus
                              maxlength="6"
                              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                              @keydown.enter.prevent="COFActiveAccountOTP()"
                              @keyup="COFActiveAccountOTP()"
                              >
                            </v-text-field>

                          </v-col>

                        </v-row>
                      </v-form>
                    
                    </div>

                    <v-row style="height:35px;" v-if="is_cof_form_enable_terms">
                      <v-col style="margin-top:0px;">
                         <p class="tos">By checking this box, I hereby agree to
                            <a href="https://shurjopay.com.bd/terms-shurjopay-checkout" target="_blank"
                            style="text-decoration: underline;text-transform:none">
                              shurjoPay Terms of Use</a>
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                </v-form>

                   
                  <div class="paynow_div" v-if="hideshowpaynow && is_cof_form_enable_btn">
                    <v-row>
                      <v-col md="12" sm="12" xm="12">
                        <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn"
                          @click="save_card_info"
                          :disabled="!isFormValid"
                        > {{this.cof_pay_now_btn_label}} </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="paynow_div" v-if="is_cof_form_enable_btn2">
                    <v-row>
                      <v-col md="12" sm="12" xm="12">
                        <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn"
                          @click="save_card_info"
                          :disabled="!isFormValidNew"
                        > {{this.cof_pay_now_btn_label}}</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  
              </v-container>
           </v-tabs-items>
          <!-- Save card section form end -->

          
          

          <!-- Saved card List start-->
          <v-tabs-items v-if="saved_card_list">

            <v-container grid-list-xl justify-space-around wrap fluid class="containerStyle" 
            style="margin-top:-15px !important; margin-bottom:-25px !important">
             <!-- scrollbar-color:#eeeeee #0F9750;margin-top:-30px !important; -->
              <v-list subheader two-line style="height:355px !important; overflow-y:auto;scrollbar-width:thin;">

              <v-list-item v-for="(card, index) in cardList" :key="index" style="border-bottom: 1px solid rgb(233, 227, 227)">
                    <v-avatar tile :title="card.scheme">
                      <span>
                          <v-img :src="masterCard" width="40%" height="auto" v-if="card.scheme=='master'"></v-img>
                          <v-img :src="visaCard" width="40%" height="auto" v-if="card.scheme=='visa'"></v-img>
                          <v-img :src="amex" width="40%" height="auto" v-if="card.scheme=='american-express'"></v-img>
                          <v-img :src="unionPay" width="40%" height="auto" v-if="card.scheme=='union-pay'"></v-img>
                          <v-img :src="dinersclub" width="40%" height="auto" v-if="card.scheme=='dinners-club'"></v-img>
                      </span>
                    </v-avatar>

                  <v-list-item-content @click="input_cvv(card.masked_pan,card.scheme,index,card.cof_id)" style="cursor:pointer">
                      
                      <v-list-item-subtitle class="cardPan">{{card.masked_pan}}</v-list-item-subtitle>
                      <v-list-item-title class="cardIdentifier">{{card.bank_name}}</v-list-item-title>
                      <v-list-item-subtitle class="NoValidCard" v-if="card.is_valid==0">
                        No valid transaction found with this card
                      </v-list-item-subtitle>

                  </v-list-item-content>
                  
                 
                    <v-list-item-action>

                        <!-- Edit Delete Icon -->
                      <v-list-item-subtitle style="max-height:56px" :id="'before_'+index">

                          <!-- <v-icon size="30px" left color="error" style="margin-left:10px;margin-top:10px;margin-right:-10px;color:#a9a9ac !important"
                            @click="editCardInfo(index)" title="Expired Card - Update It" v-if="card.expiry_date=='1'">mdi-pencil-box
                          </v-icon> -->

                          <v-icon size="30px" left color="error" style="margin-left:10px;margin-top:10px;margin-right:6px" 
                            @click="deleteCardInfo(card.cof_id,index)" title="Delete this card">mdi-delete
                          </v-icon>

                      </v-list-item-subtitle>

                      <!-- Input CVV part -->
                      <v-form v-model="isFormValid2">
                        <v-list-item-subtitle style="max-height:56px;display:none" :id="'after_'+index">
                            <v-text-field
                                v-model="cvv_pin.one"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                :rules="cvvRules"
                                autocomplete="off"
                                outlined
                                required
                                maxlength="4"
                                placeholder="CVV/CVC"
                                append-icon="mdi-credit-card"
                                style="width:110px"
                                type="password"
                                @blur="CheckState(index)"
                            >
                            </v-text-field>
                        </v-list-item-subtitle>
                      </v-form>

                  </v-list-item-action>
                
              </v-list-item>
               <v-divider></v-divider>

                <!-- Payment using another card -->
                <v-list-item style="height:56px;margin-right:5px;" @click="show_cards(2)" id="AnotherCardPay" v-if="isShowStaticPart">
                  <v-icon size="35px" left class="add_card_icon">mdi-credit-card-plus</v-icon>
                  <v-list-item-content>
                    <v-list-item-title class="listItems">Pay using another card</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- Terms of services -->
                 <v-list-item style="margin-right:5px;border:2px solid #f0f2f4;background-color:rgb(248, 248, 248);max-height:73px !important" v-if="isShowStaticPart">
                  <v-list-item-content>
                    <p class="terms_and_services">
                      You have agreed to <a href="https://shurjopay.com.bd/terms-shurjopay-checkout" target="_blank"
                      class="terms_and_services_anchor">shurjoPay Terms of Use</a>
                       which is limited to facilitating your payment.
                    </p>
                  </v-list-item-content>
                </v-list-item>

                <!-- Pay Now Button -->
                <v-list-item v-if="isShowStaticPart">
                  
                    <v-row>
                      <v-col md="12" sm="12" xm="12" style="padding:0px !important">
                        <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn" 
                        @click="PayFromSaveCard()" :disabled="!isFormValid2">{{this.saved_card_paynow_label}}</v-btn>
                      </v-col>
                    </v-row>
                 
                </v-list-item>
               

          </v-list>

            </v-container>
          </v-tabs-items>


           <!-- Card Delete popup -->
           <v-tabs-items v-if="deleteCardConfirmationDialog">
             <v-container grid-list-xl justify-space-around wrap fluid class="containerStyle">
              <v-dialog v-model="deleteConfirmationCard" persistent max-width="350">

                <v-card>
                  <v-card-title style="font-size:18px;font-weight:bolder"> Are you sure to delete this card?</v-card-title>
                  <!-- <v-card-text></v-card-text> -->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="DeleteCardNo()">No</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteCardYes()">Yes</v-btn>
                  </v-card-actions>

                </v-card>
              </v-dialog>
             </v-container>
           </v-tabs-items>
           <!-- Saved card List end-->

          <v-tabs-items v-if="show_terms_conditions">
              <v-row justify="center" >
              <v-dialog v-model="toc_dialog" scrollable max-width="400px" persistent>
                <v-card>
                  <v-card-title>Term & Conditions</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 320px;">
                    <p style="text-align:justify"><br>
                      We respect your privacy and want to protect your personal information. Data that we collect<br><br>

                      <b>1)</b> I understand that if at any time any information furnished against shurjoPay or
                      any kind of fraudulent transaction is found incorrect then the contact shall be
                      deemed to be cancelled and shall be liable for action as per shurjoMukhi decision<br><br>
                      
                      <b>2)</b>The merchant will reserve all the content-related responsibilities and copyright issues.
                       It is the sole responsibility of you to ensure that any information entered in the relevant fields
                        is correct, accurate, and valid.The merchant needs to abide by all the Bangladesh Bank Laws. 
                        The merchant also needs to confirm that he has gone through and understood the AML/CFT doc
                         file shared by shurjoMukhi Limited.<br><br>

                      <b>3)</b> I hereby certify that I have carefully read the terms and conditions of shurjoPay 
                      and I undertake to comply with the terms and conditions therein.<br><br>

                    </p>

                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" dark type="submit" @click="toc_dialog = false" 
                      style="float:right;text-transform: capitalize">Close</v-btn> 
                  </v-card-actions>

                </v-card>
              </v-dialog>
                </v-row>
          </v-tabs-items>

            <!-- Mobile Banking -->
            <v-tabs-items v-if="mobile_banking">
              <v-container grid-list-xl  wrap fluid class="mobileBankingSec" >
                <v-layout wrap class="rowStyle" >
                  <v-flex xs4 md4 sm4 v-for="(mfs,index) in mfs_gateways" :key="index">

                    <v-hover style="max-height:67px !important" v-slot:default="{ hover}">
                      <v-img
                        :src ="image_base_url+ mfs.hover_image"
                        :alt = "mfs.slug"
                        max-width = "100%"
                        @click="go_mfs_gateway(mfs.gateway_id,mfs.gateway_type_id)"
                        :class=" hover ? 'hover_img': 'normal_img'"
                        :title = "mfs.method_name"
                      >
                        <v-img
                          :src ="image_base_url+ mfs.image"
                          v-if="hover"
                          max-width = "100%"
                          :title = "mfs.method_name"
                        >
                       </v-img>

                        <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ mfs.image"
                          v-if="mfs.gateway_id==mfs_selected_gateway_iddd"
                          max-width = "100%"
                        >
                       </v-img>

                      </v-img>

                    </v-hover>
                   

                  </v-flex>
                </v-layout>
              </v-container>

            </v-tabs-items>

            <!-- Internet banking -->
            <v-tabs-items v-if="i_banking">
              <v-container grid-list-xl justify-space-around wrap fluid class="internetBankingSec">
                 <v-layout wrap class="rowStyle">
                  <v-flex xs4 md4 sm4 v-for="(ibanking,index) in ibanking_gateways" :key="index">
                    <v-hover style="max-height:67px !important" v-slot:default="{ hover }">
                      <v-img
                        :src ="image_base_url+ ibanking.hover_image"
                        :alt = "ibanking.slug"
                        max-width = "100%"
                        @click="go_ibanking_gateway(ibanking.slug,ibanking.gateway_id,ibanking.gateway_type_id)" 
                        :class=" hover ? 'hover_img': 'normal_img'"
                        :title="ibanking.method_name"
                      >
                       <v-img
                        :src ="image_base_url+ ibanking.image"
                        v-if="hover"
                        max-width = "100%"
                        :title="ibanking.method_name"
                       >
                      </v-img>

                       <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ ibanking.image"
                          v-if="ibanking.gateway_id==ibanking_selected_gateway_iddd"
                          max-width = "100%"
                        ></v-img>

                      </v-img>
                     
                    </v-hover>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tabs-items>

             <!-- Loading Actions -->
             <!-- hide-overlay  -->
            <v-tabs-items v-if="loading_tab">
               <v-dialog v-model="loading_dialog" persistent width="385">
                <v-card color="#257D3E" dark >
                  <v-card-text class="text-center">
                    <p style="font-size:17px;font-weight:bold;color:#fff;margin-bottom:4px;padding-top:10px">
                      Please wait while processing ...
                    </p>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-tabs-items>

            <!-- User account --Login,Signup,Forgot Password -->
            <v-tabs-items v-if="user_account">
              <v-container>

                <div v-if="login_div" style="min-height:250px;margin-top:20px">
                  <v-form ref="form5">
                  <v-row justify="center">
                    <v-col md="10" sm="10" xs="10" style="height:60px">

                      <VuePhoneNumberInput
                          v-model="loginItem.login_mobile_number"
                          default-country-code="BD"
                          color="#0F9750"
                          valid-color="#0F9750"
                          error-color="#FF0000"
                          :translations="translations"
                          clearable 
                          class="mb-2"
                          required
                          @update="onUpdate"
                          no-example
                          @keydown.enter.prevent="login_status()"

                        />  
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col md="10" sm="10" xs="10" style="height:60px">
                      <v-text-field
                        name="password"
                        v-model="loginItem.login_password"
                        :value="myPass"
                        label="Password *"
                        :rules="[passwordRules.required, passwordRules.min]"
                        prepend-inner-icon="mdi-lock"
                        :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="() => (value = !value)"
                        :type="value ? 'password' : 'text'"
                        outlined
                        dense
                        id="passwordField"
                        @keydown.enter.prevent="login_status()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center" align="end" style="margin-bottom:-15px;margin-top:-10px;">
                      <v-col md="4" sm="4" xs="4">
                        <!-- -->
                      </v-col>

                      <v-col md="8" sm="8" xs="8">
                        <v-btn text small color="primary" class="forgot_btn" @click="forgot_password">Forgot Password ?</v-btn>
                      </v-col>
                  </v-row>

                  </v-form>
                  <v-list>
                    <v-list-item style="margin-top:0px">

                      <v-list-item-subtitle style="padding-right:6px" class="text-left align-self-start">
                        <v-btn 
                        text small
                        color="primary"
                        dark
                        @click="show_singup_form()" 
                        style="text-transform:capitalize;"
                        >Create Account</v-btn>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="text-right align-self-start">
                        <v-btn color="primary" elevation="1" dark  @click="login_status" 
                        style="text-transform:capitalize;margin-right:20px">Login</v-btn>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>

                <!-- Recover Password Modal...Take Mobile Number -->
                  <v-row justify="center">
                    <v-dialog v-model="forgot_password_modal" max-width="400px" persistent>
                      <v-card>

                        <v-card-title class="justify-center">
                            <v-icon size="58px" color="primary">mdi-lock-reset</v-icon>
                        </v-card-title>

                        <v-card-title class="justify-center" style="margin-top:-30px">Recover Password</v-card-title>
                        
                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form2" @submit.prevent="ForgotPasswordSendOTP">
                                    <VuePhoneNumberInput
                                      v-model="forgot_password_mobile"
                                      default-country-code="BD"
                                      color="#0F9750"
                                      valid-color="#0F9750"
                                      error-color="#FF0000"
                                      :translations="translations"
                                      clearable 
                                      class="mb-2"
                                      required
                                      @update="onUpdate"
                                      style="min-height:120px !important"
                                      no-example
                                    />  
                                  </v-form>
                                </v-list-item-content>
                            </v-list-item>
      
                            <v-list-item>

                              <v-list-item-subtitle style="padding-right:6px" class="text-left align-self-start">
                                <v-btn large color="red" dark @click="send_otp_back()" style="text-transform: capitalize">Close</v-btn>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle class="text-right align-self-start">
                                  <v-btn large color="primary" dark type="submit" @click="ForgotPasswordSendOTP" 
                                  style="text-transform: capitalize;margin-left:12px">Next</v-btn>
                              </v-list-item-subtitle>

                            </v-list-item>
                                
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                <!-- Forgot Password Modal ... Take OTP Input -->
                  <v-row justify="center">
                    <v-dialog v-model="forgot_password_otp_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-subtitle class="justify-center" style="padding-top:30px;font-size:15px;margin-left:15px">
                          A text message with a verification code was just sent to <b> {{this.otp_send_text_msg}} </b>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list subheader two-line>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form_otp" @submit.prevent="ForgotPasswordSubmitOTP">
                                    <v-text-field
                                      v-model="forgot_password_otp"
                                      :rules="[v => !!v || 'OTP is required']"
                                      label="Enter OTP"
                                      autocomplete="off"
                                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                      required
                                      outlined
                                      autofocus
                                      maxlength="8"
                                    >
                                    </v-text-field>
                                  </v-form>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item style="margin-top:-33px">
                                <v-list-item-subtitle style="padding-right:6px" class="text-left align-self-start">
                                  <v-btn large color="red" dark @click="submit_otp_back" style="text-transform: capitalize">Close</v-btn>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle class="text-right align-self-start">
                                    <v-btn large color="primary" dark type="submit" @click="ForgotPasswordSubmitOTP" style="float:right;text-transform: capitalize">Next</v-btn>
                                </v-list-item-subtitle>
                              </v-list-item>

                            </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                <!-- Reset New Password Modal ...Take new password as a input-->
                  <v-row justify="center">
                    <v-dialog v-model="new_password_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-title class="justify-center">Set New Password</v-card-title>
                        <v-card-text>
                            <v-list subheader two-line>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form_password_reset" @submit.prevent="resetPassword">
                                    <v-text-field
                                      v-model="new_password"
                                      name="password"
                                      :value="new_password_val"
                                      :rules="[v => !!v || 'New password is required']"
                                      label="New Password"
                                      autocomplete="off"
                                      :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                      @click:append="() => (value = !value)"
                                      :type="value ? 'password' : 'text'"
                                      required
                                      outlined
                                      autofocus>
                                    </v-text-field>
                                    
                                    <v-text-field
                                      v-model="confirmed_new_password"
                                      :value="confirmed_new_password_val"
                                      :rules="[(new_password === confirmed_new_password) || 'Password must match']"
                                      label="Confirmed Password"
                                      autocomplete="off"
                                      :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                      @click:append="() => (value = !value)"
                                      :type="value ? 'password' : 'text'"
                                      required
                                      outlined>
                                    </v-text-field>
                                  </v-form>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item style="margin-top:-33px">
                                <v-list-item-subtitle style="padding-right:6px" class="text-left align-self-start">
                                  <v-btn large color="red" dark @click="resetPassword_back" style="text-transform: capitalize">Close</v-btn>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle class="text-right align-self-start">
                                  <v-btn large color="primary" dark type="submit" @click="resetPassword" style="float:right;text-transform: capitalize">Submit</v-btn>
                                </v-list-item-subtitle>
                              </v-list-item>

                            </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>
                
                <!-- Registration OTP Modal...Take OTP input for account activation -->
                  <v-row justify="center">
                    <v-dialog v-model="resigtration_otp" max-width="400px" persistent>
                      <v-card>
                        <v-card-subtitle class="justify-center" style="padding-top:30px;font-size:15px;margin-left:15px">
                            A text message with a verification code was just sent to <b> {{this.resigter_otp_text}} </b>
                        </v-card-subtitle>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="register_otpp" @submit.prevent ="register_otp_confirm">
                                  <v-text-field
                                    v-model="resigter_otp"
                                    :rules="[v => !!v || 'OTP is required']"
                                    label="Enter OTP"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                    required
                                    outlined
                                    dense
                                    autocomplete="off"
                                    autofocus
                                    maxlength="8"
                                  >
                                  </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item style="margin-top:-33px">

                                <v-list-item-subtitle style="padding-right:6px;margin-top:11px" class="text-left align-self-start">
                                  <v-btn v-if="dispaly_time_otp" large color="#009688" dark>
                                    <v-icon left>mdi-alarm</v-icon>{{display_min}}:{{display_sec}}
                                  </v-btn>
                                  <v-btn v-if="display_resend_otp_text" large color="#009688" dark @click="resend_otp" 
                                    style="text-transform: capitalize;font-size:13px">{{resent_otp_text}}
                                  </v-btn>
                                </v-list-item-subtitle>
                                
                                <v-list-item-subtitle style="padding-right:6px">
                                  <v-btn large color="red"  dark @click="close_reg_otp_modal" style="float:right;text-transform: capitalize">Close</v-btn>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                  <v-btn large color="primary" type="submit" dark @click="register_otp_confirm" style="float:right;text-transform: capitalize">Submit</v-btn>
                                </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                  <!--Active account ...If the user miss account varification during registration.When login check account activation  -->
                  <v-row justify="center">
                    <v-dialog v-model="active_account_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-title class="justify-center" style="font-size: 15px;color: red;font-weight: bold;">
                          Your account is not active, Please Active.
                        </v-card-title>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="form22" @submit.prevent="send_active_otp">
                                <v-text-field
                                  v-model="active_account_mobile"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  :rules="mobileNumberRule"
                                  label="Mobile Number"
                                  prepend-inner-icon="mdi-cellphone-basic"
                                  required
                                  outlined
                                  autocomplete="off"
                                  autofocus
                                  >
                                </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item style="margin-top:-33px">
                              <v-list-item-content style="padding-right:6px">
                                <v-btn large color="red" dark @click="active_account_modal=false" style="text-transform: capitalize">Close</v-btn>
                              </v-list-item-content>

                              <v-list-item-content>
                                <v-btn large color="primary" type="submit" dark @click="send_active_otp" style="float:right;text-transform: capitalize">Active Account</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                  <!-- Confirm OTP Modal for account activation-->
                  <v-row justify="center">
                    <v-dialog v-model="active_acc_otp" max-width="400px" persistent>
                      <v-card>
                         <v-card-title class="justify-center" style="font-size: 15px;color: red;font-weight: bold;">
                          Your account is not active, Please Active.
                        </v-card-title>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="active_otpp" @submit.prevent="active_otp_confirm">
                                  <v-text-field
                                    v-model="active_account_otp_input"
                                    :rules="[v => !!v || 'OTP is required']"
                                    label="Enter OTP"
                                    required
                                    outlined
                                    autocomplete="off"
                                    autofocus
                                    maxlength="8"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                    >
                                    </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>


                          <v-list-item style="margin-top:-33px">

                              <v-list-item-content style="padding-right:6px">
                                <v-btn v-if="dispaly_time_otp" large color="#009688" dark>
                                  <v-icon left>mdi-alarm</v-icon>{{display_min}}:{{display_sec}}
                                </v-btn>
                                <v-btn v-if="display_resend_otp_text" large color="#009688" dark @click="ActiveAccountResendOtp" 
                                  style="text-transform: capitalize;font-size:13px">{{resent_otp_text}}
                                </v-btn>
                              </v-list-item-content>
                              
                              <v-list-item-content style="padding-left:6px">
                                <v-btn large color="red"  dark @click="active_acc_otp=false" style="float:right;text-transform: capitalize">Close</v-btn>
                              </v-list-item-content>

                              <v-list-item-content>
                                <v-btn large color="primary" type="submit" dark @click="active_otp_confirm" style="float:right;text-transform: capitalize">Submit</v-btn>
                              </v-list-item-content>
                          </v-list-item>

                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                </div>

                <div v-if="signup_div" style="margin-top:5px;min-height:282px">
                  <v-form ref="form_register">
                    <v-row justify="center">
                      <v-col md="9" sm="9" xs="9" style="height:60px;padding:12px">
                          <VuePhoneNumberInput
                            v-model="signup_items.mobile_number"
                            default-country-code="BD"
                            color="#0F9750"
                            valid-color="#0F9750"
                            error-color="#FF0000"
                            :translations="translations"
                            clearable 
                            class="mb-2"
                            required
                            @update="onUpdate"
                            no-example
                            @keydown.enter.prevent="register()"
                          />  
                         
                      </v-col>
                    </v-row>

                    <v-row justify="center" style="margin-top:-8px">
                      <v-col md="9" sm="9" xs="9" style="height:64px">
                       
                          <v-text-field
                            v-model="signup_items.password"
                            name="password"
                            :value="myPass"
                            label="Password"
                            :rules="[SignUpPasswordRules.required, SignUpPasswordRules.min]"
                            prepend-inner-icon="mdi-key"
                            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            outlined
                            required
                            maxlength = "15"
                            dense
                            @keydown.enter.prevent="register()"
                          >
                          </v-text-field>
                         
                      </v-col>
                    </v-row>

                    <!-- <v-row justify="center" style="margin-top:-8px">

                      <v-col md="9" sm="9" xs="9" style="height:60px">
                        <v-text-field
                            v-model="signup_items.email_address"
                            label="Email address"
                            prepend-inner-icon="mdi-email"
                            autocomplete="off"
                            maxlength="100"
                            outlined
                            dense
                            id="email_style"
                          >
                          </v-text-field> 
                      </v-col>
                    </v-row> -->

                    <v-row justify="center" style="margin-top:-8px">

                      <v-col md="9" sm="9" xs="9" style="height:60px">
                        <v-text-field
                            v-model="signup_items.fullname"
                            :rules="fullnameRules"
                            label="Full name"
                            prepend-inner-icon="mdi-account"
                            oninput="this.value = this.value.replace(/[^a-zA-Z _ . -]/g, '').replace(/(\..*)\./g, '$1');"
                            autocomplete="off"
                            required
                            outlined
                            dense
                            maxlength="50"
                            @keydown.enter.prevent="register()"
                          >
                          </v-text-field> 

                      </v-col>
                    </v-row>
                  </v-form>

                <v-row justify="center" style="margin-top:-4px">
                  <v-col md="6" sm="6" xs="6" style="height:38px">
                      <v-btn block color="primary" dark style="margin-top:0px" @click="register">Sign Up</v-btn>
                  </v-col>
                </v-row>
                </div>

                <div v-if="myaccount">
                    <MyAccount></MyAccount>
                </div> 
                
              </v-container>
            </v-tabs-items>

          
          
          <v-tabs-items>

            <!-- <v-row justify="center" align="center">
              <v-dialog v-model="popup_dialog" persistent max-width="390px">
                <v-tabs v-model="currentItem" centered height="55px">
                  
                  <v-tab href="#save_bks_num" @click="show_saved_bks()" >Saved Number</v-tab>
                  <v-tab href="#cancel_agreement">Agreement ID Cancel</v-tab>
                  
                </v-tabs>

                 <v-tabs-items v-model="currentItem">
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <h2>Hello</h2>
                        Content
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>


              </v-dialog>
            </v-row> -->

            <v-row justify="center" align="center">
              <v-dialog v-model="popup_dialog" persistent max-width="390px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form3" @submit.prevent="send_bkh">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" xs="12">
                        <v-text-field
                          v-model="others.mobile_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Your bKash Account number"
                          prepend-inner-icon="mdi-cellphone-basic"
                          placeholder="e.g 01XXXXXXXXX"
                          :rules="mobileNumberRule"
                          required
                          outlined
                          maxlength="11"
                          autocomplete="off"
                          style="margin-bottom:-30px;margin-top:36px"
                          autofocus
                          id="bks"
                        >
                        </v-text-field>
                        </v-col>
                      </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-subtitle class="text-center" style="font-size:12px; margin-top:-25px" v-if="myaccount && hasNumSaved">Your saved bKash account numbers</v-card-subtitle>
                 
                  <div style="margin-top:-22px;min-height:130px" v-if="myaccount && hasNumSaved">
                    <ul style="list-style-type:none;margin-left:14px;" v-for="(b,index) in saved_bkash" :key="index">
                      <li style="float:left;"><v-img :src="bkash_img" width="50px" max-height="27px"></v-img></li>
                      <li style="float:left;margin-left:10px">{{b.mobileno}}</li>
                      <li style="float:left;margin-left:20px">
                        <v-btn text small  @click ="transfer_number(b.mobileno)" style="text-transform: capitalize;font-size:14px">Select</v-btn>
                       </li>
                      <li style="float:left;margin-left:10px">
                        <v-btn text small @click="delete_bks_num(b.mobileno,index)">
                          <v-icon size="28px" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </li>
                    </ul>

                  </div>

                  <v-list>
                    <v-list-item style="margin-left:20px;margin-right:20px">
                      <v-list-item-content style="padding-right:6px">
                        <v-btn large color="error" dark @click="closePopup" style="text-transform: capitalize">Close</v-btn>
                      </v-list-item-content>

                      <v-list-item-content>
                          <v-btn large color="primary" dark type="submit" @click="send_bkh" style="float:right;text-transform: capitalize">Next</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>




           <v-tabs-items>
            <v-row justify="center" align="center">
              <v-dialog v-model="tdrAdditionalFeePopUp" persistent max-width="390px">
                <v-card>
                  <v-card-text>
                    <v-container>

                      <p style="text-align: center;font-size: 16px;color: #000;font-weight:bold">
                        Charges and Additions</p>
                      <p style="text-align: center;font-size: 14px;color: #000;margin-top:-16px">
                        These are the charges against the total amount</p>

                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" >Title</th>
                              <th class="text-left" >Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td >Covenience Charge</td>
                              <td >{{additional_fees_amt}}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-btn 
                        color="primary"
                        style="margin-top:13px;text-transform:none"
                        elevation="0"
                        @click="tdrAdditionalFeePopUp = false">
                        Its Okay
                      </v-btn>

                    </v-container>
                  </v-card-text>
                  
                 
                  

                 

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>


          <!-- Bkash Agreement Dialog start-->
           <v-tabs-items>
            <v-row justify="center" align="center">
              <v-dialog v-model="AgreementPopupDialog" persistent max-width="350px">
                <v-card>
                  <v-card-subtitle class="text-center" style="font-size:17px;padding-top:25px;color:#000;font-family: Times New Roman;">
                    Are you sure want to cancel Agreement ?
                  </v-card-subtitle>
                 
                  <v-list>
                    <v-list-item style="margin-left:20px;margin-right:20px">
                      
                       <v-list-item-content style="padding-right:6px">
                          <v-btn large color="primary" dark type="submit" @click="agreementYes" style="float:right;text-transform: capitalize">Yes</v-btn>
                      </v-list-item-content>

                      <v-list-item-content >
                        <v-btn large color="error" dark @click="CloseAgreementPopupDialog" style="text-transform: capitalize">No</v-btn>
                      </v-list-item-content>

                     
                    </v-list-item>
                  </v-list>

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>
           <!-- Bkash Agreement Dialog end-->


          </v-tabs-items>
        </v-card>
      </div>
      <!-- Tabs or Nav section end -->

       <!-- Cancel Button Start -->
      <div style="margin-top:5px;padding-left:5px;padding-right:5px">
        <v-row justify="center">
          <v-col md="8" sm="12" xs="12">
              <p style="font-size:12px;margin-bottom:7px;text-align:center"> 
                <span @click="CancelPayment" style="text-decoration: underline;color:#0f9750;cursor:pointer;">Click Here
                </span> to cancel this transaction</p>
          </v-col>
        </v-row>
      </div>
       <!-- Cancel Button End -->


       <!-- paynow tdr -->
       <div class="paynow_div_tdr" v-if="tdr_visibility_pay_now_btn">
          <v-row>
            <v-col md="12" sm="12" xm="12">
              <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn_tdr"
              @click="tdrPayment()"
              > Pay Now </v-btn>
            </v-col>

          </v-row>
                         
       </div>

     <!-- footer section start -->
        <v-footer style="height:50px;margin-top:-10px;background-color:#f2f2f2 !important">
        <v-col md="6" sm="6" xs="6">
          <p 
            class="text-right"
            style="font-size:14px;color:#00592B;font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;"
          >Powered by</p>
        </v-col>

        <v-col md="6" sm="6" xs="6">
          <v-img
            :src="require('./assets/logos/sp_footer_logo.png')"
            alt="ShurjoPay Logo"
            width="77px"
            style="margin-top:-18px;margin-left:-15px"
          >
          </v-img>
        </v-col>
      </v-footer>
      <!-- footer section end -->

      
      </v-card>

    </div>

      <div v-if="isUnauthorized">
          <UnauthorizedCom></UnauthorizedCom>
      </div>

       <div v-if="isMaintenanceMode">
          <Maintenance></Maintenance>
      </div>

  
    <v-main>
       
    </v-main>
  </v-app>
</template>

<script>
import MyAccount from './components/MyAccount';
import UnauthorizedCom from './components/UnauthorizedCom';
import Maintenance from './components/Maintenance';
import SavedCardList from './components/SavedCardList';
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
const pg_url = require('../pg_url');
const CryptoJS = require("crypto-js");
import Cookies from 'js-cookie';
import VuePhoneNumberInput from "vue-phone-number-input";
import jwt_decode from "jwt-decode";
import BISC from './components/BISC';


export default {
  
  name: 'App',
  components:{
    MyAccount,
    UnauthorizedCom,
    Maintenance,
    SavedCardList,
    VuePhoneNumberInput,
    BISC,
  },
  
  data: () => ({

    //BISC
    is_bisc_display:false,

    snackbar: false,
    color: "#0F9750",
    mode: "",
    timeout: 4000,
    popup_text: "",

    base_url: pg_url.base_url,
    image_base_url: pg_url.image_base_url,
    merchant_logo_image_base_url: pg_url.merchant_logo_image_base_url,
    cancel_base_url: pg_url.cancel_base_url,
    customer_api_base_url:pg_url.customer_api_base_url,

    merchant_logo:"",
    merchant_name:"",
    customer_name:"",

    bkash_img:require('./assets/logos/bkash_mobile.png'),
  
    hasNumSaved:false,
    fastPayStatus:false,
    active_tab: '',
    card:false,
    popup_dialog:false,
    colWidth:12,
    is_emi_visible:false,
    is_emi_active:0,
    checkbox: false,
    toc_checkbox:false,
    selectedTab:null,
    active_bar:null,

    loading_tab:false,
    loading_dialog:false,

    card_tab:false,
    saved_card_tab1:false,
    mobile_banking:false,
    i_banking:false,
    user_account:false,
    cardSkeletonLoader:true,

    attrs: {},

    login_div:true,
    myaccount:false,
    no_login:true,
    forgot_password_modal:false,
    forgot_password_otp_modal:false,

    active_account_modal:false,
    active_account_mobile:"",
    active_acc_otp:false,
    active_account_otp_input:"",
     
    signup_div:false,
    value: String,
    isFormValid: false,
    isFormValid2:false,
    isFormValidNew:false,
    other_cards_display:false,
    hideCard_div:true,
    hidecardmenu:true,
    hideshowpaynow:true,
    myPass:"",
    new_password_val:"",
    confirmed_new_password_val:"",

    visaActive:true,
    visaDeactive:false,
    masterCardActive:true,
    masterCardDeactive:false,
    amexActive:true,
    amexDeactive:false,
    unionpayActive:true,
    unionpayDeactive:false,
    dinersActive:true,
    dinersDeactive:false,

    sp_orderid:"",
    sp_amount:"",
    sp_amount_display:"",

    sp_currency:"",
    sp_token:"",

    sp_return_url:"",
    sp_cancel_url:"",
      
    isMainDiv:false,
    isUnauthorized:false,
    isMaintenanceMode:false,
    emi_options:"",
    saved_bkash:"",

    editedItem:{
      card_number: "",
      mm: "",
      yyyy:"",
      mm_yy:"",
      cvc_cvv: "",
      card_holder_name: "",
      emi: "",
    },

    loginItem:{
      login_mobile_number: "",
      login_password: "",
    },

    signup_items:{
      fullname: "",
      mobile_number: "",
      email_address: "",
      password: "",
    },

    others:{
      mobile_number:"",
    },

    mobileNumberRule: [
      v => !!v || "Mobile number is required",
      v => /^-?\d*$/.test(v)|| "Mobile number can only be numbers",
    ],

    mobile_num_sign_up:[
      v => !!v || "Mobile number is required",
      v => /^-?\d*$/.test(v)|| "Mobile number can only be numbers",
    ],

    passwordRules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',    
    },

    fullnameRules: [
      v => !!v || "Full name is required",
    ],
       
    CardNumberRules:[
      v => !!v || "Card number is required",
      //v => /^(5[1-5]{1}[0-9]{2}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{4})|(4{1}[0-9]{3}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{1}|(4{1}[0-9]{3}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{4})|(3[4|7]{1}[0-9]{2}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{3})|(3[0|6|8]{1}[0-9]{2}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{2})|(6[0-9]{3}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{4}))$/.test(v)
      v => /^(5[1-5]{1}[0-9]{2}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{4})|(4{1}[0-9]{3}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{1}|(4{1}[0-9]{3}[\s]?[0-9]{4}[\s]?[0-9]{4}[\s]?[0-9]{4}))$/.test(v)
    ],

    mmyyRule:[
      v => !!v || "MM/YY is required",
      v => /^(0[1-9]{1}\/[0-9]{2})|(1[0|1|2]{1}\/[0-9]{2})$/.test(v) ||"Invalid format"
    ],

    cvc_cvvRules:[
      v => !!v || "CVV/CVC is required",
      v => /^[0-9]{3,4}$/.test(v) ||"CVV/CVC must be at least 3 digits long"
    ],

    forgot_password_mobile:"",
    forgot_password_otp:"",
    saved_card_section:false,
    new_password_modal:false,
    new_password:"",
    confirmed_new_password: "",
    resigtration_otp:false,
    resigter_otp:"",

    user_entered_card_name:"",
    isSelectedCard:false,
    otp_send_text_msg:"",
    
    resigter_otp_text: "",
    resent_otp_text:"Resend OTP",
    display_min:0,
    display_sec:0,
    dispaly_time_otp:true,
    display_resend_otp_text:false,
    active_account_otp_input_text:"",
      
    /*Tabs visibility Dynamic */
    isCard:false,
    isMbanking:false,
    isIBanking:false,

    /*Dynamic Payment Gateways*/
    cards_gateways:"",
    mfs_gateways:"",
    ibanking_gateways:"",

    dwidth:"",

    show_terms_conditions:false,
    toc_dialog:false,
    display_card_number:"",
    store_id:0,
    invoiceno: "",

    AgreementPopupDialog:false,
    agreementStatus:2,

    //token validity
    isValidToken:true,

    // tdr
    is_add_commission:0,
    normal_user:1,

    additional_fees_amt:0,
    payable_amt:0,

    mfs_gateway_id_for_tdr:0,
    gateway_type_id_tdr:0,
    card_name_tdr:"",
    tdr_visibility:0,
    tdr_visibility_pay_now_btn:0,
    tdrAdditionalFeePopUp:false,

    mfs_selected_gateway_iddd:0,
    cards_selected_gateway_iddd:0,
    ibanking_selected_gateway_iddd:0,

    //saved card lsit
    saved_card_list:false,

    cvv_pin:{
      one:"",
      saved_card_name:"",
      saved_card_cof_id:"",
      card_mask_pan:"",
    },
    cardList:"",
    card_name:"",
    card_number:"",
    
    cvvRules:[
      v => !!v || "CVV/CVC is required",
      v=> /^[0-9]{3,4}$/.test(v) ||"CVV/CVC must be at least 3 digits long"
    ],


    default_card_image: require("./assets/logos/master.png"),
    masterCard: require("./assets/logos/master.png"),
    visaCard: require("./assets/logos/visa.png"),
    amex: require("./assets/logos/amex.png"),
    unionPay:require("./assets/logos/unionpay.png"),
    dinersclub:require("./assets/logos/diners_club.png"),

    deleteCardConfirmationDialog:false,
    deleteConfirmationCard:false,
    isShowStaticPart:false,

    reset_password_mobile:"",

    isSavedCardSwitch: false,
    delete_card_id:"",
    delete_card_ui_id:"",

    loginBool:0,
    is_card_on_file_active:0,
       
    //Phone Number Validation
    translations: {
      countrySelectorLabel: 'Country Code',
      countrySelectorError: 'Choose a country',
      phoneNumberLabel: 'Mobile Number',
      example: 'Example'
    },
    resultsMobile: {},

    cof_pay_now_btn_label:"Pay Now",
    saved_card_paynow_label:"Pay Now",

    // new changes
    showCreateAccountForm:false,
    showCOFRegisterOTPForm:false,
    cof_user_reg_mobile:"",
    cof_register_otp_confirm:"",
    cof_pin_number:"",
    cof_auto_login_country_code:"",

    COFLoginPasswordRules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',    
    },

    cof_signup:{
      mobile_number: "",
      password: "",
      fullname: "",
      email_address: "",
    },

    cof_login_pin:"",
    cof_login_mobile_number:"",

    cof_reg_otp_confirm:{
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      six: "",
    },

    is_cof_form_enable:true,
    is_cof_form_enable_terms:true,
    is_cof_form_enable_btn:true,
    is_cof_form_enable_btn2:false,
    is_mask_card_enable:false,
    mask_card_number:"",
    mask_card_icon:"",
    isCheckLoginEnable:true,
    customer_trxn_mobile_number:"",

    isCOFLoginFormEnable:false,

    logout_tab_decision:"",

    SignUpPasswordRules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
          
    },
    selected_other_card_list:"",
    paymentToHeight:"60",
    leftBorderHeight:"40",
    AccountActiveDeactiveDiv:false,
    cof_active_account_mobile:"",
    cof_active_account_otp:"",
    total_assigned_tabs:0,

  
  }),


  created(){
    /* 
     *The created hook can be used to run code after an instance is created.
     *In this hook we include the function which need before mounted.
    */
  },

  mounted() {

    this.sp_orderid = this.$route.query.order_id;
    this.sp_token = this.$route.query.token;

    if(typeof(this.sp_token)==='undefined'){
      this.isMainDiv = false;
      this.isUnauthorized = true;
      this.isMaintenanceMode = false; 
      this.is_bisc_display = false;
    }
    else{
      const options = {
        method: 'POST',
        url: this.base_url+'check-token',
        headers: {
          Authorization: 'Bearer '+this.sp_token
        }
      };
      axios.request(options).then(response =>{
          
        if(response.data.sp_code== '200'){ 
          
          var decoded_token = jwt_decode(this.sp_token);
          var s_id = decoded_token.sub;
          
          if(s_id == 530){
            this.isMainDiv = false;
            this.is_bisc_display = true;
            this.isUnauthorized = false;
            this.isMaintenanceMode = false;
          }

          else{
            this.isMainDiv = true;
            this.isUnauthorized = false;
            this.isMaintenanceMode = false;
            this.is_bisc_display = false;
            this.check_card_holder_login();
            this.MerchantInfo(this.sp_orderid);
          }

        }

        else if(response.data.sp_code== '503'){
          this.isMainDiv = false;
          this.isUnauthorized = false;
          this.is_bisc_display = false;
          this.isMaintenanceMode = true; 
          setTimeout(this.CancelURL, 500);
        }

        else if(response.data.sp_code== '1064'){
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.isMaintenanceMode = false;
          this.is_bisc_display = false; 
          setTimeout(this.CancelURL, 500);
        }
          
      }).catch(error =>{
        if (error.response.status == 401) {
            this.isMainDiv = false;
            this.isUnauthorized = true;
            this.isMaintenanceMode = false;
            this.is_bisc_display = false;
          }
      });
      }

      setTimeout(this.generate_invoice, 600);
      this.UnauthorizedTimeRemaining(15);
     
  },

  computed:{
    _seconds:() => 1000,
    _mintues(){
      return this._seconds*60;
    },
     _hours(){
      return this._mintues*60;
    },

    MobileNumberOutput () {
        return Object.keys(this.resultsMobile)
    },
  },

  watch:{
   
    "editedItem.mm_yy":function(val){
      let number_length = val.length;
      let firstPart;
      if(number_length == 2){
        firstPart = val +'/';
        this.editedItem.mm_yy = firstPart;
      }
    },

    "editedItem.card_number":function(val){
  
      var inputlen = val.length;
      if(inputlen==0 || inputlen ==1){
        this.visaDeactive=false;
        this.visaActive=true;
        this.masterCardDeactive=false;
        this.masterCardActive=true;
        this.amexDeactive=false;
        this.amexActive=true;
        this.unionpayDeactive=false;
        this.unionpayActive=true;
        this.dinersDeactive=false;
        this.dinersActive=true;

        this.isSelectedCard = false;
      }

      // if(inputlen<7 && this.is_emi_active == 1){
      //   this.colWidth = 12;
      //   this.is_emi_visible = false;
      // }
          
      if(inputlen==2){
        this.select_card_icon(val);
      }

      // if(inputlen == 7 && this.is_emi_active == 1){
      //   var bin_number_with_space = this.editedItem.card_number;
      //   var bin_number = Number(bin_number_with_space.replace(/\s/g, ''));
      //   this.CallEmiApi(bin_number);
      // }

      // if(inputlen > 11 && this.is_emi_active == 1){
      //   var bin_number_with_space2 = this.editedItem.card_number;
      //   var full_card_number = bin_number_with_space2.replace(/\s/g, '');
      //   var bin_number2 = Number(full_card_number.slice(0, 6));
      //   this.CallEmiApi(bin_number2);
      // }

      if(inputlen >11){
        this.select_card_icon(val);
      }
    },
     
    "signup_items.email_address":function (val) {
       var format =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       var validate_email = val.match(format);
       var fetch_id = document.getElementById('email_style');
       if(!validate_email){
         fetch_id.style.color ="#ff5252";
       }
       else{
         fetch_id.style.color ="rgba(0, 0, 0, 0.87)";
       }
    },

    "cof_reg_otp_confirm.one":function(val){
      var otp_length = val.length;
      if(otp_length==1){
        document.getElementById("cof_two").focus();
      }
    },

    "cof_reg_otp_confirm.two":function(val){
      var otp_length2 = val.length;
      if(otp_length2==1){
        document.getElementById("cof_three").focus();
      }
    },

    "cof_reg_otp_confirm.three":function(val){
      var otp_length3 = val.length;
      if(otp_length3==1){
        document.getElementById("cof_four").focus();
      }
    },

    "cof_reg_otp_confirm.four":function(val){
      var otp_length4 = val.length;
      if(otp_length4==1){
        document.getElementById("cof_five").focus();
      }
    },

    "cof_reg_otp_confirm.five":function(val){
      var otp_length5 = val.length;
      if(otp_length5==1){
        document.getElementById("cof_six").focus();
      }
    },

    "cof_login_mobile_number":function(val){
    
      if(val !== null){
       
        var mobile_number_without_hypen = val.replace(/-/g, "");
        var first_char =  mobile_number_without_hypen.slice(0, 1);
       
          if(first_char == '0' && mobile_number_without_hypen.length == 11){
            this.CheckMobileExistsOrNot();
          }

          if(first_char != '0' && mobile_number_without_hypen.length == 10){
            this.CheckMobileExistsOrNot();
          }
      }

    }

  },


  methods: {

    OTPReverse(id) {
      var previous_value = id;
      document.getElementById(previous_value).focus();
    },

    MerchantInfo(orderid){
  
      const options = {
        method: 'GET',
        url: this.base_url+'merchant-config/'+orderid,
        headers: {
          'Authorization': 'Bearer '+this.sp_token,
          'Content-Type': 'application/json'
        },
      };
     
      axios.request(options).then(response =>{
  
          this.sp_amount = response.data.gateway_info.request_amount;
          this.sp_amount_display = parseFloat(response.data.gateway_info.request_amount).toFixed(2);
          this.sp_currency = response.data.gateway_info.currency;
          this.store_id = response.data.gateway_info.store_id;
          this.customer_trxn_mobile_number = response.data.gateway_info.customer_phone_no;
          // this.sp_return_url = response.data[0].return_url;
          // this.sp_cancel_url = response.data[0].cancel_url;

          this.is_card_on_file_active = response.data.merchant_info[0].is_card_on_file;
          this.is_add_commission = response.data.merchant_info[0].is_add_commission;
          this.is_emi_active = response.data.merchant_info[0].is_emi;

          var CardVisibility = response.data.merchant_info[0].is_cards;
          var MbankingVisibility = response.data.merchant_info[0].is_mbanking; 
          var IBnakingVisibility = response.data.merchant_info[0].is_ibanking;

          this.isCard = CardVisibility;
          this.isMbanking = MbankingVisibility;
          this.isIBanking = IBnakingVisibility;
          this.merchant_name = response.data.merchant_info[0].merchant_name;


          //Dynamic Merchant div height
          if(this.merchant_name.length>=1 && this.merchant_name.length<=30){
            this.paymentToHeight ="60";
            this.leftBorderHeight ="40";
          }

          else if(this.merchant_name.length>=31 && this.merchant_name.length<=46){
            this.paymentToHeight ="80";
            this.leftBorderHeight ="60";
          }

          else if(this.merchant_name.length>=47 && this.merchant_name.length<=65){
            this.paymentToHeight ="95";
            this.leftBorderHeight ="72";
          }

          else{
            this.paymentToHeight ="60";
            this.leftBorderHeight ="40";
          }
          
          if(response.data.merchant_info[0].store_logo === null){
            this.merchant_logo = require('./assets/test_merchant_logos/shurjopay_logo.png');
          }

          if(response.data.merchant_info[0].store_logo !== null){
            this.merchant_logo = this.merchant_logo_image_base_url + response.data.merchant_info[0].store_logo;
          }

          // Assigned gateways
          if(response.data.gateway_info.bank.length>0){
            this.cards_gateways = response.data.gateway_info.bank;
          }

          if(response.data.gateway_info.mfs.length>0){
            this.mfs_gateways = response.data.gateway_info.mfs;
          }

          if(response.data.gateway_info.internet.length>0){
            this.ibanking_gateways = response.data.gateway_info.internet;
          }

           // Active Tabs Logic
            if(response.data.merchant_info[0].tab_priority==1){
              this.logout_tab_decision = "1";
              this.active_tabs(1);
            }

            else if(response.data.merchant_info[0].tab_priority==2){
              this.logout_tab_decision = "2";
              this.active_tabs(2);
            }

            else if(response.data.merchant_info[0].tab_priority==3){
              this.logout_tab_decision = "3";
              this.active_tabs(3);
            }

            else{
              this.logout_tab_decision = "4";

              if(CardVisibility == 1){
                this.active_tabs(1);
              }

              if(CardVisibility == 0 && MbankingVisibility == 2){
                this.active_tabs(2);
              }

              if(CardVisibility == 0 && MbankingVisibility == 0 && IBnakingVisibility == 3){
                this.active_tabs(3);
              }

              
            }

            // Design
            var counter=0;
            for (let i = 1; i <=3; i++) {
              if(CardVisibility == i || MbankingVisibility == i || IBnakingVisibility == i){
                counter++;
              }
            }

            this.total_assigned_tabs = counter;

            if(counter==3){
              this.dwidth = "width:25%";
            }

            if(counter==2){
              this.dwidth = "width:33.33%";
            }
           
            if(counter==1){
              this.dwidth ="width:50%"
            }

       }).catch(error =>{
        console.log(error);
      });

    },

    CallEmiApi(bin){

      var header_token = this.$route.query.token;
      var orderid = this.$route.query.order_id;
      var store_id = this.store_id;
      
      const options = {
        method: 'POST',
        url: this.base_url+'emi-check',
        headers: {Authorization: 'Bearer '+header_token},
        data:{bin,orderid,store_id}
      };
     
      axios.request(options).then(response =>{
        
        if(response.data[0].sp_code == 200){
          this.colWidth = 6;
          this.is_emi_visible = true;
         
          var setemi_options = [];
          var i;
          for (i = 0; i <(response.data).length; i++) {
            setemi_options.push({
              id: i,
              option_val: response.data[i].emi_month+' months- '+(response.data[i].amount).toFixed(2)+ '/Month' ,
              select_val:response.data[i].emi_month+' months',
            });
            
          }
          this.emi_options = setemi_options;
        }

        else{
          this.is_emi_visible = false;
          this.colWidth = 12;
        }
        
      }).catch(error =>{
        this.is_emi_visible = false;
        this.colWidth = 12;
        console.log(error);
      });

    },

    CheckLoginFunction(){
      var customer_token = this.getCookie("token");
      if(customer_token !==""){
        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-token-check',
          headers: {
            Authorization: 'Bearer '+customer_token
          },
        };
        axios.request(options).then(response =>{
          if(response.data.sp_code == '200'){
            this.loginBool = 1;
          }
          else{
            this.loginBool = 0;
          }
          
        }).catch(error =>{
            this.loginBool = 0;
        });
      }
      else{
        this.loginBool = 0;
      }
      
    },

    checkMonthYearValidity(){
      let val = this.editedItem.mm_yy;
      let month_length = val.length;

      if(month_length == 1){
        if(val>='2' && val<='9'){
          this.editedItem.mm_yy="0"+val+"/";
        }
      }

      if(month_length == 3){
          let remove_char = val.slice(0, -1);
          if(remove_char>=13 || remove_char=='00'){
            this.editedItem.mm_yy="";
          }  
      }

      if(month_length==5){
        let rem_year = val.slice(-2);
        let previous_str = val.slice(0,-3);
        let current_year = new Date().getFullYear().toString().substr(-2);
        if(rem_year < current_year ){
          this.editedItem.mm_yy = previous_str;
        }
        else{
          document.getElementById("cvc_cvv").focus();
        } 
      }
      
    },

    // COF start

    CheckLogin(){

      if(this.checkbox == true){

        if(this.editedItem.card_number!=="" && this.editedItem.mm_yy!=="" && this.editedItem.cvc_cvv!=="" && this.editedItem.card_holder_name!==""){

          if(this.loginBool == 1){
            this.showCreateAccountForm = false;
            this.showCOFRegisterOTPForm = false;
            this.isCOFLoginFormEnable = false;
          }

          if(this.loginBool == 0){
            this.isFormValid = false;
            var token = this.$route.query.token;
            var mobile_no = this.customer_trxn_mobile_number;
            const options = {
              method: 'POST',
              url: this.base_url+'customer/phone-number-check',
              headers: {Authorization: 'Bearer '+token},
              data: {mobile_no},
            };
            axios.request(options).then(response =>{

              this.isFormValid = false;
              this.is_cof_form_enable = false;
              this.is_cof_form_enable_terms = false;
              this.is_cof_form_enable_btn = false;
              this.is_mask_card_enable = true;

              var non_mask_card_number = this.editedItem.card_number;
              var first_four_digits = non_mask_card_number.slice(0, 4);
              var last_four_digit = non_mask_card_number.slice(-4);
              this.mask_card_number = first_four_digits+" **** " + " ****"+" "+last_four_digit;

  
              // Customer exists
              if(response.data.sp_code=='200'){
                this.isCOFLoginFormEnable = true;

                
                if(this.customer_trxn_mobile_number.length == 11){
                  this.cof_login_mobile_number = this.customer_trxn_mobile_number;
                }

                else if(this.customer_trxn_mobile_number.length == 13){
                  this.cof_login_mobile_number = this.customer_trxn_mobile_number.slice(2);
                }
                else if(this.customer_trxn_mobile_number.length == 14){
                  this.cof_login_mobile_number = this.customer_trxn_mobile_number.slice(3);
                }

                else{
                  this.cof_login_mobile_number = this.customer_trxn_mobile_number;
                }
                
                
              }

              // Customer does not exists
              else{
                this.cof_signup.mobile_number = this.customer_trxn_mobile_number;
                this.cof_signup.fullname = this.editedItem.card_holder_name;
                this.showCreateAccountForm = true;
                this.showCOFRegisterOTPForm = false;
                this.isCOFLoginFormEnable = false;
              }


            }).catch(error =>{
                console.log(error);
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = "Something went wrong...";
              });

          }

         

        }

        else{
          this.snackbar = true;
          this.color = "success";
          this.timeout = 2000;
          this.popup_text = "Fill up the card information first";
          this.showCreateAccountForm = false;
          this.showCOFRegisterOTPForm = false;
          this.isCOFLoginFormEnable = false;
         
          var input_element = document.getElementById("check_login_checkbox_id");
          input_element.click();

        }
        
      }


      else{
        this.showCreateAccountForm = false;
        this.showCOFRegisterOTPForm = false;
        this.is_cof_form_enable = true;
        this.is_cof_form_enable_terms = true;
        this.is_cof_form_enable_btn = true;
        this.is_mask_card_enable = false;
        this.isCOFLoginFormEnable = false;
        this.AccountActiveDeactiveDiv = false;
      }

      
    },

    COFregister(){

       if(this.resultsMobile["isValid"]){

          if(this.cof_signup.password !=="" && this.cof_signup.fullname !==""){

            var token = this.$route.query.token;

            var countryCode = this.resultsMobile["countryCode"];
            var formattedNumber = this.resultsMobile["formattedNumber"];
           
            var email = this.cof_signup.email_address;
            var name = this.cof_signup.fullname;
            var password = this.cof_signup.password;
            var password_confirmation = password;
            var mobile_no = formattedNumber;

            this.cof_user_reg_mobile = formattedNumber;
            
            const options = {
              method: 'POST',
              url: this.customer_api_base_url+'customer-sign-up',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                name,
                email,
                password,
                password_confirmation,
                mobile_no,
                countryCode
              },
              
              };
              axios.request(options).then(response =>{
                
                if(response.data.sp_code == '200'){
                    this.showCreateAccountForm = false;
                    this.showCOFRegisterOTPForm = true;
                    this.cof_user_reg_mobile = formattedNumber;
                    this.cof_pin_number = password;
                    this.cof_auto_login_country_code = countryCode;
                    this.isFormValid = false;


                    this.is_cof_form_enable_btn = false;

                    this.is_cof_form_enable_btn2 = true;

                    this.isCheckLoginEnable = false;
                    this.dispaly_time_otp = true;
                    this.display_resend_otp_text = false;
                    this.showRemaining(3);
                }

                else{
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 2000;
                  this.popup_text = response.data.message;
                  this.isFormValid = false;
                  this.isCheckLoginEnable = true;
                }

              }).catch(error =>{
                console.log(error);
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = "Something went wrong...";
                this.isFormValid = false;
                this.isCheckLoginEnable = true;
              });
          }

          else{
            this.snackbar = true;
            this.color = "red";
            this.timeout = 2000;
            this.popup_text = "Please Fill Up required fields";
            this.isFormValid = false;
            this.isCheckLoginEnable = true;
          }

       }

       else{
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Mobile Number is not valid";
          this.isFormValid = false;
          this.isCheckLoginEnable = true;
       }

       this.isFormValid = false;

    },

    COFSubmitOTP(){

      this.isFormValid = false;
      this.isCheckLoginEnable = false;
      
      var customer_entered_otp = this.cof_reg_otp_confirm.one+this.cof_reg_otp_confirm.two+
      this.cof_reg_otp_confirm.three+this.cof_reg_otp_confirm.four+this.cof_reg_otp_confirm.five+this.cof_reg_otp_confirm.six;
     
      if(customer_entered_otp.length == 6 && this.cof_user_reg_mobile !=="" ){
        
        var token = this.$route.query.token;
        var verify_code = customer_entered_otp;
        var mobile_no = this.cof_user_reg_mobile;
        
        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-verify-account',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
              verify_code,
              mobile_no
          },
          
        };

        axios.request(options).then(response =>{

          if(response.data.sp_code == '111'){
            this.COFCustomerAutoLogin(mobile_no);
          }

          else if(response.data.sp_code == '101'){
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "OTP does not matched";
          }

          else if(response.data.sp_code =='103'){
            this.snackbar = true;
            this.color = "red";
            this.timeout = 2000;
            this.popup_text = response.data.message;
          }
          else{
            this.snackbar = true;
            this.color = "red";
            this.timeout = 2000;
            this.popup_text = response.data.message;
          }
        
        }).catch(error =>{
          console.log(error);
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Something Went Wrong";
        });
          
      }

      if(customer_entered_otp.length < 5 && this.cof_user_reg_mobile ==="" ){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 2000;
        this.popup_text = "Please fill up OTP fields";
      }

    },


    COFResendOTP(){
      var token = this.$route.query.token;
      var mobile_no = this.cof_user_reg_mobile;
        const options = {
        method: 'POST',
        url: this.customer_api_base_url+'customer-resend-code',
        headers: {
          Authorization: 'Bearer '+token
        },
        data:{
          mobile_no
        },
        };

          axios.request(options).then(response =>{
          if(response.data.sp_code == '200'){
            this.snackbar = true;
            this.color = "success";
            this.timeout = 4000;
            this.popup_text = "A New OTP sent to " + this.cof_user_reg_mobile;

            this.dispaly_time_otp = true;
            this.display_resend_otp_text = false;
            this.showRemaining(3);
          }
          else{
              this.snackbar = true;
              this.color = "red";
              this.timeout = 4000;
              this.popup_text = response.data.message;
          }

        }).catch(error =>{
            console.log(error);
        });
    },


    COFCustomerAutoLogin(mobile_no){
      
      this.isFormValid = false;
      if(mobile_no !==""){

        var countryCode = this.cof_auto_login_country_code;
        var token = this.$route.query.token;
        var password_real = this.cof_pin_number;

        var CryptoJSAesJson = {
          stringify: function (cipherParams) {
            var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
          },
        }

        var key = this.$route.query.order_id;
        var orderid = this.$route.query.order_id;
        var password = CryptoJS.AES.encrypt(JSON.stringify(password_real),key,{format: CryptoJSAesJson}).toString();


        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-login',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
            mobile_no,
            password,
            countryCode,
            orderid
          },
        
        };

        axios.request(options).then(response =>{
          //When the user is active and successful login
          if(response.data.is_active == 1 && response.data.sp_code == '200'){

            this.is_cof_form_enable_btn2 = false;

            this.customer_name = response.data.customer_name;
            this.login_div = false;
            this.myaccount= true;
            this.no_login=false;
            this.loginBool = 1;

            this.showCreateAccountForm = false;
            this.showCOFRegisterOTPForm = false;
            this.isCOFLoginFormEnable = false;

            // this.isFormValid = true;
            // this.is_cof_form_enable = true;
            // this.is_cof_form_enable_terms = true;
            // this.is_cof_form_enable_btn = true;
            // this.is_mask_card_enable = false;

            this.isFormValid = false;
            this.is_cof_form_enable = false;
            this.is_cof_form_enable_terms = false;
            this.is_cof_form_enable_btn = true;
            this.is_mask_card_enable = true;
            this.isCheckLoginEnable = false;

           

            //Set cookie information
            var cookie_name = "token";
            var cookie_value = response.data.token;
            var exipry_from_token = response.data.expires_in;
            let date = new Date();
            date.setTime(date.getTime() + (exipry_from_token * 1000));
            let cookie_time = "expires=" + date.toUTCString();
            document.cookie = cookie_name + "=" + cookie_value + "; " + cookie_time + "; ";
          }

          //Password not matched
          else if(response.data.sp_code == '1064'){
            this.snackbar = true;
            this.color = "error";
            this.timeout = 2000;
            this.popup_text = response.data.message;
          }

          //No such account exist,Wrong Mobile number
          else if(response.data.sp_code == '102'){
            this.snackbar = true;
            this.color = "error";
            this.timeout = 6000;
            this.popup_text = response.data.message;
          }

          //Account is not active popup account active modal
          else if(response.data.is_active == 0 && response.data.sp_code == '200'){
            this.showCreateAccountForm = false;
            this.isCOFLoginFormEnable = false;
            this.showCOFRegisterOTPForm = true;
           
          }
        
        }).catch(error =>{
            console.log(error);
            this.snackbar = true;
            this.color = "red";
            this.popup_text = "Something went wrong...";
        });
        
      }

    },


    COFLoginAction(){

      if(this.resultsMobile["isValid"]){
        if(this.cof_login_pin !==""){

        var countryCode = this.resultsMobile["countryCode"];
        var formattedNumber = this.resultsMobile["formattedNumber"];
        var token = this.$route.query.token;
        var mobile_no = formattedNumber;
        var password_real = this.cof_login_pin;

        var CryptoJSAesJson = {
          stringify: function (cipherParams) {
            var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
          },
        }

        var key = this.$route.query.order_id;
        var orderid = this.$route.query.order_id;
        var password = CryptoJS.AES.encrypt(JSON.stringify(password_real),key,{format: CryptoJSAesJson}).toString();

        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-login',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
            mobile_no,
            password,
            countryCode,
            orderid
          },
        
        };
        
        axios.request(options).then(response =>{
          //When the user is active and successful login
          if(response.data.is_active == 1 && response.data.sp_code == '200'){

            this.is_cof_form_enable_btn2 = false;
            this.customer_name = response.data.customer_name;
            this.login_div = false;
            this.myaccount= true;
            this.no_login=false;
            this.loginBool = 1;

            this.showCreateAccountForm = false;
            this.showCOFRegisterOTPForm = false;
            this.isCOFLoginFormEnable = false;
            this.AccountActiveDeactiveDiv = false;

            // this.isFormValid = true;
            // this.is_cof_form_enable = true;
            // this.is_cof_form_enable_terms = true;
            // this.is_cof_form_enable_btn = true;
            // this.is_mask_card_enable = false;

            this.isFormValid = false;
            this.is_cof_form_enable = false;
            this.is_cof_form_enable_terms = false;
            this.is_cof_form_enable_btn = true;
            this.is_mask_card_enable = true;
            this.isCheckLoginEnable = false;
            
           
            //Set cookie information
            var cookie_name = "token";
            var cookie_value = response.data.token;
            var exipry_from_token = response.data.expires_in;
            let date = new Date();
            date.setTime(date.getTime() + (exipry_from_token * 1000));
            let cookie_time = "expires=" + date.toUTCString();
            document.cookie = cookie_name + "=" + cookie_value + "; " + cookie_time + "; ";
          }

          //Password not matched
          else if(response.data.sp_code == '1064'){
            this.snackbar = true;
            this.color = "error";
            this.timeout = 2000;
            this.popup_text = response.data.message;
          }

          //No such account exist with this Mobile number
          //Redirect to create an account
          else if(response.data.sp_code == '102'){
            this.cof_signup.mobile_number = mobile_no;
            this.cof_signup.fullname = this.editedItem.card_holder_name;
            this.showCreateAccountForm = true;
            this.showCOFRegisterOTPForm = false;
            this.isCOFLoginFormEnable = false;
            this.AccountActiveDeactiveDiv = false;
          }

          //Account is not active popup account active modal
          else if(response.data.is_active == 0 && response.data.sp_code == '200'){
            
            this.showCreateAccountForm = false;
            this.showCOFRegisterOTPForm = false;
            this.COFsendActiveOTPAuto(formattedNumber);

          }
        
        }).catch(error =>{
            console.log(error);
            this.snackbar = true;
            this.color = "red";
            this.popup_text = "Something went wrong...";
        });
        
        }

        else{
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Please Fill up required fields";  
        }

      }

      else{
        this.snackbar = true;
        this.color = "red";
        this.timeout = 2000;
        this.popup_text = "Mobile Number is not valid";
      }
     
    
    },

    CheckMobileExistsOrNot(){

      if(this.resultsMobile["isValid"]){
        var forward_mobile_number = this.resultsMobile["phoneNumber"];
        var formattedNumber = this.resultsMobile["formattedNumber"];
        var token = this.$route.query.token;
        var mobile_no = formattedNumber;

        const options = {
          method: 'POST',
          url: this.base_url+'customer/phone-number-check',
          headers: {Authorization: 'Bearer '+token},
          data: {mobile_no},
        };

        axios.request(options).then(response =>{

          if(response.data.sp_code=='404'){

            this.snackbar = true;
            this.color = "success";
            this.timeout = 3000;
            this.popup_text = "No account exists.Create an account";

            this.cof_signup.mobile_number = forward_mobile_number;
            this.cof_signup.fullname = this.editedItem.card_holder_name;
            this.showCreateAccountForm = true;
            this.showCOFRegisterOTPForm = false;
            this.isCOFLoginFormEnable = false;
          }

          if(response.data.sp_code=='200'){
            this.isCOFLoginFormEnable = true;
            this.showCreateAccountForm = false;
            this.showCOFRegisterOTPForm = false;
            //this.cof_login_mobile_number = this.customer_trxn_mobile_number;
            this.cof_login_mobile_number = forward_mobile_number;
          }


        }).catch(error =>{
          console.log(error);
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Something went wrong...";
        });


      }

      // else{
      //   this.snackbar = true;
      //   this.color = "red";
      //   this.timeout = 2000;
      //   this.popup_text = "Mobile Number is not valid";
      // }


    },


    COFsendActiveOTPAuto(formattedNumber){

        var mobile_no = formattedNumber;
        var token_check = this.$route.query.token;

        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-resend-code',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no
          },
        };

        axios.request(options).then(response =>{

          if(response.data.sp_code == '200'){
            this.cof_active_account_mobile = formattedNumber;
            this.isCOFLoginFormEnable = false;
            this.AccountActiveDeactiveDiv = true;
          }

          else{
            this.isCOFLoginFormEnable = true;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = response.data.message;
          }
        
      }).catch(error =>{
          this.isCOFLoginFormEnable = true;
          console.log(error);
      }); 
    },


    COFActiveAccountOTP(){

      if(this.cof_active_account_otp.length==6){

        var token = this.$route.query.token;
        var verify_code = this.cof_active_account_otp;
        var mobile_no = this.cof_active_account_mobile;

        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-verify-account',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
              verify_code,
              mobile_no
          },
          
        };

        axios.request(options).then(response =>{

          if(response.data.sp_code == '111'){
            this.isCOFLoginFormEnable = true;
            this.AccountActiveDeactiveDiv = false;
          }

          else{
            this.snackbar = true;
            this.color = "red";
            this.timeout = 2000;
            this.popup_text = response.data.message;
          }

        }).catch(error =>{
          console.log(error);
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Something Went Wrong";
        });


      }

    },



    //applied during mounted
    CheckBackBtnClick(){
      var order_id = this.$route.query.order_id;
      var sp_token = this.$route.query.token;
   
      const options = {
        method: 'POST',
        url: this.base_url+'method-verification',
        headers: {
          Authorization: 'Bearer'+ sp_token
        },
        data: {
          order_id
          },
        };

        axios.request(options).then(response =>{
          if(response.data.message==1){
            this.CancelPayment();
          }
          
          }).catch(error =>{
              console.log(error);
          });
    },

    //no need token
    CancelURL(){
       var order_id = this.$route.query.order_id;
       const options = {
          method: 'POST',
          url: this.cancel_base_url+'cancel',
          data: {
            order_id
           },
          };
          axios.request(options).then(response =>{
           if(response.data.message==1){
             window.location.href = response.data.url;
           }
           else{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
           }
          }).catch(error =>{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
          });
    },

    //token implemented
    CanceledPaymentAPI(){
      var order_id = this.$route.query.order_id;
      var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'canceled',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            order_id
           },
          };

          axios.request(options).then(response =>{
           if(response.data.message==1){
             window.location.href = response.data.url;
           }
           else{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
           }
          }).catch(error =>{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
          });
    },

    CancelPayment(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.CanceledPaymentAPI();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //later
    agreementYes(){
      this.agreementStatus = 1;
      
      var mobile_no = this.others.mobile_number;
      const options = {
        method: 'POST',
        url: this.base_url+'bkash-cancel-agreement',
        headers: {
           Authorization: 'Bearer '+this.sp_token
        },
        data: {
          mobile_no
        },

        };
         axios.request(options).then(response =>{
           if(response.data.message==1){
              this.snackbar = true;
              this.color = "success";
              this.timeout =2000;
              //this.popup_text = "Agreement cancellation successful";
              this.popup_text = "Agreement creation has cancelled";
              this.AgreementPopupDialog = false;
           }
           else{
              this.AgreementPopupDialog = false;
           }
          
         
        }).catch(error =>{
           console.log(error);
        });
    },

    //later
    agreementMethod(){
      var mobile_no = this.others.mobile_number;
      const options = {
        method: 'POST',
        url: this.base_url+'bkash-agreement-chk',
        headers: {
           Authorization: 'Bearer '+this.sp_token
        },
        data: {
          mobile_no
        },

        };
         axios.request(options).then(response =>{
           if(response.data.message==1){
             this.AgreementPopupDialog = true;
           }
         
        }).catch(error =>{
           console.log(error);
        });

    },

    //no need token
    CloseAgreementPopupDialog(){
      this.agreementStatus = 2;
      this.AgreementPopupDialog = false;
    },

    generate_invoice(){
      
      var date = new Date();
      var ms_part = date.getMilliseconds();
      
      var orderID = this.$route.query.order_id;
      var order_id_part = orderID.substring(0, 3);
      
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for ( let i = 0; i <6; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      let random_string = result.trim();
      this.invoiceno = order_id_part+random_string+ms_part;
    },


    showAdditionalFeesPopUp(){
      this.tdrAdditionalFeePopUp = true;
    },

   /**
     * Ibanking Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfoIBanking(card_name,store_id,gateway_id,gateway_type_id){
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'get-tdr-charge',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data: {
          store_id,
          gateway_id
          },
        };
        axios.request(options).then(response =>{
          
          var perchange  = response.data[0].tdr_charge;
          
          if(perchange!=0){
            var amount_initiate = Number(this.sp_amount);
            var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
            this.additional_fees_amt = (additional_fees_amount).toFixed(2);
            var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
            this.payable_amt = payable_amount_bdt;

            this.tdr_visibility= this.is_add_commission;
            this.tdr_visibility_pay_now_btn = this.is_add_commission;
            this.normal_user = 0;

            this.gateway_type_id_tdr = gateway_type_id;
            this.card_name_tdr = card_name;

          }
          else{
            //console.log("No TDR set for that gateway...");
            this.tdr_visibility= 0;
            this.tdr_visibility_pay_now_btn = 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.gateway_type_id_tdr = gateway_type_id;
            this.goDirectIBankingGateway(card_name);
          }
          
        }).catch(error =>{
            console.log(error);
        }); 
    },

    //token implemented
    go_ibanking_gateway(card_name,gateway_id,gateway_type_id){
      this.ibanking_selected_gateway_iddd = gateway_id;
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.AssignIBankingGatway(card_name,gateway_id,gateway_type_id);
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    AssignIBankingGatway(card_name,gateway_id,gateway_type_id){

      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;
      if(is_add_commission_tdr==1)
      {
        //console.log("is_add_commission enabled");
        this.tdrComissionInfoIBanking(card_name,store_id,gateway_id,gateway_type_id);
      }
      else
      {
       //console.log("No is_add_commission enabled");
       this.goDirectIBankingGateway(card_name);
      }

    },

    goDirectIBankingGateway(card_name){
      if(card_name === ""){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 6000;
          this.popup_text = "No card name is found.";
      }

      if(card_name !== ""){
        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }

        if(amount<10){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 3000;
          this.popup_text = "Amount Cannot be less than 10 Taka.";
        }
        else{
          this.loading_tab = true;
          this.loading_dialog = true;
          var store_id = this.store_id;
          var orderid = this.$route.query.order_id;
          var emi_str = this.editedItem.emi;
          var wordpos = emi_str.search("months");
          var emi_month = emi_str.substring(0, wordpos-1);
          var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
          var sp_token = this.$route.query.token;
          var invoiceno = this.invoiceno;
          const options = {
              method: 'POST',
              url: this.base_url+'only-card-pay',
              headers: {
                Authorization: 'Bearer '+ sp_token
              },
              data:{
                card_name,
                store_id,
                orderid,
                emi_month,
                emi_amt,
                invoiceno
              },
            };
              axios.request(options).then(response =>{
                if(response.data.message==1){
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  window.location.href = response.data.url;
                }
                else{
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 3000;
                  this.popup_text = "Something went wrong";
                }

              }).catch(error =>{
                console.log(error);
              }); 
         }
      }

    },


    /**
     * shurcharage/tdr payment action btn
     */

    tdrPayment(){
      var gateway_id = this.mfs_gateway_id_for_tdr;
      var gateway_type_id = this.gateway_type_id_tdr;

      var card_name = this.card_name_tdr;

      if(gateway_type_id==1){
        this.goDirectCardsGateWay(card_name)
      }

      if(gateway_type_id==2){
        this.goDirectMFSGateWay(gateway_id);
      }

      if(gateway_type_id==3){
        this.goDirectIBankingGateway(card_name)
      }
    },

    /**
     * MFS Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfo(store_id,gateway_id){
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'get-tdr-charge',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            store_id,
            gateway_id
           },
          };
          axios.request(options).then(response =>{
            
            var perchange  = response.data[0].tdr_charge;

            if(perchange!=0){

              var amount_initiate = Number(this.sp_amount);
              var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
              
              this.additional_fees_amt = (additional_fees_amount).toFixed(2);
              var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
              this.payable_amt = payable_amount_bdt;

              this.tdr_visibility= this.is_add_commission;
              this.tdr_visibility_pay_now_btn = this.is_add_commission;
              this.normal_user = 0;

            }
            else{
              this.tdr_visibility= 0;
              this.tdr_visibility_pay_now_btn = 0;
              this.normal_user = 1;
              this.payable_amt = 0;

              this.goDirectMFSGateWay(gateway_id);

            }
            

          }).catch(error =>{
             console.log(error);
          }); 
    },

    AssignMFSGateway(gateway_id,gateway_type_id){
      // console.log("Gateway ID:"+gateway_id);
      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;

      if(is_add_commission_tdr==1)
      {
        if(gateway_id==14){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==16){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==21){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==22){
          //console.log("It's Bkash");
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }

        if(gateway_id==18){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==24){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==23){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==25){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        } 
      }
      else
      {
       //console.log("No is_add_commission setted");
       this.goDirectMFSGateWay(gateway_id);
      }

    },

    goDirectMFSGateWay(gateway_id){
       if(gateway_id==14){
          this.ROCKET();
        }
        if(gateway_id==16){
          this.MCASH();
        }
        if(gateway_id==21){
          this.NAGAD();
        }
        if(gateway_id==22){
          this.BKASH();
        }

        if(gateway_id==18){
          this.TCASH();
        }
        if(gateway_id==24){
          this.OKWALLET();
        }
        if(gateway_id==23){
          this.UPAY();
        }
        if(gateway_id==25){
          this.SHURECASH();
        } 
    },

    go_mfs_gateway(gateway_id,gateway_type_id) {

      this.mfs_selected_gateway_iddd = gateway_id;
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.AssignMFSGateway(gateway_id,gateway_type_id);
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
     
    },



    //no need token
    showRemaining(total){

      var t = new Date();
      var getMin = t.getMinutes();
      var getSec = t.getSeconds();
      var getMiliSec = t.getMilliseconds();
      
      const timer = setInterval(() =>{
      const now = new Date();
      var getYear = now.getFullYear();
      var getMonth = now .getMonth();
      var getDate = now.getDate();
      var getHour = now.getHours();
      
      const end = new Date(getYear,getMonth,getDate,getHour,getMin+total,getSec,getMiliSec);
        const distance = end.getTime() - now.getTime();
        if(distance<0){
          this.dispaly_time_otp = false;
          this.display_resend_otp_text =true;
        clearInterval(timer);
        return
        }
        const minutes = Math.floor((distance % this._hours) / this._mintues);
        const seconds = Math.floor((distance % this._mintues) / this._seconds);

        this.display_min = minutes < 10 ? '0' + minutes : minutes;
        this.display_sec = seconds < 10 ? '0' + seconds : seconds;
      },1000);
    },

    //no need token
    UnauthorizedTimeRemaining(timeInMinutes){
      var t = new Date();
      var getMin = t.getMinutes();
      var getSec = t.getSeconds();
      var getMiliSec = t.getMilliseconds();
      
      const timer = setInterval(() =>{
      const now = new Date();
      var getYear = now.getFullYear();
      var getMonth = now .getMonth();
      var getDate = now.getDate();
      var getHour = now.getHours();
      
      const end = new Date(getYear,getMonth,getDate,getHour,getMin+timeInMinutes,getSec,getMiliSec);
        const distance = end.getTime() - now.getTime();
        if(distance<0){
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.isMaintenanceMode = false;
          this.is_bisc_display = false;
          //window.location.href = this.sp_cancel_url;
          this.CancelURL();
        clearInterval(timer);
        return
        }
      },1000);
    },

    //later
    bkash_num_list(){
       var sp_token = this.$route.query.token;
       var mobile_no = localStorage.getItem("mobile_no");
       const options = {
          method: 'POST',
          url: this.base_url+'bkash-mobile-list',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            mobile_no
           },
          };
          axios.request(options).then(response =>{
            if(response.data.message ==2){
                this.hasNumSaved = false;
            }
            else{
               this.saved_bkash = response.data;
               this.hasNumSaved = true;
            }
          }).catch(error =>{
             console.log(error);
          });
    },

    //checked during render
    check_order(){
       var orderid = this.$route.query.order_id;
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'chk-order',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            orderid
           },
          };

          axios.request(options).then(response =>{
            this.merchant_info(response.data[0].store_id);
            this.sp_amount = response.data[0].payable_amount;
           
            this.sp_amount_display = parseFloat(response.data[0].payable_amount).toFixed(2);
            
            this.sp_currency = response.data[0].currency;
            this.store_id = response.data[0].store_id;
            this.sp_return_url = response.data[0].return_url;
            this.sp_cancel_url = response.data[0].cancel_url;
          }).catch(error =>{
             console.log(error);
          });
    },

    //checked during render within check_order method
    merchant_info(store_idd){
       var store_id = store_idd;
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'get-merchant-info',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            store_id
           },
          };
          axios.request(options).then(response =>{
            this.is_card_on_file_active = response.data[0].is_card_on_file;
            this.is_add_commission = response.data[0].is_add_commission;
            this.is_emi_active = response.data[0].is_emi;
            var CardVisibility = response.data[0].is_cards;
            var MbankingVisibility = response.data[0].is_mbanking; 
            var IBnakingVisibility = response.data[0].is_ibanking;
            this.isCard = CardVisibility;
            this.isMbanking = MbankingVisibility;
            this.isIBanking = IBnakingVisibility;
            this.merchant_name = response.data[0].merchant_name;
            /**
             * If no merchant logo is set for merchnat than shows this
             */
            if(response.data[0].store_logo === null){
              this.merchant_logo = require('./assets/test_merchant_logos/shurjopay_logo.png');
            }

            /**
             * If merchnat logo is set for merchnat than shows this
             */
            if(response.data[0].store_logo !== null){
              this.merchant_logo = this.merchant_logo_image_base_url + response.data[0].store_logo;
            }

            
            /*Active Tabs Logic*/
            var tabs_val = [CardVisibility, MbankingVisibility,IBnakingVisibility];
            var f_tabs = [];
            var j=0;
            for (let i = 0; i < tabs_val.length; i++) {
              const element = tabs_val[i];
              if(element !==0){
                f_tabs[j] = tabs_val[i];
                j++;
              }
            }
            //console.log("Active Tabs"+f_tabs[0]);
            this.active_tabs(f_tabs[0]);
           

            var counter=0;
            for (let i = 1; i <=3; i++) {
              if(CardVisibility == i || MbankingVisibility == i || IBnakingVisibility == i){
                counter++;
              }
            }
            if(counter==3){
              this.dwidth = "width:25%";
            }

            if(counter==2){
              this.dwidth = "width:33.33%";
            }
           
            if(counter==1){
              this.dwidth ="width:50%"
            }
           
          }).catch(error =>{
             console.log(error);
          });
    },

    
    merchant_gateway(gateway_type_id){

      if(gateway_type_id == 1){
        this.cards_gateways;
      }

      if(gateway_type_id == 2){
        this.mfs_gateways;
      }

      if(gateway_type_id == 3){
        this.ibanking_gateways;
      }
      
    },

    save_card_info(){
      var CryptoJSAesJson = {
        stringify: function (cipherParams) {
          var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
          if (cipherParams.iv) j.iv = cipherParams.iv.toString();
          if (cipherParams.salt) j.s = cipherParams.salt.toString();
          return JSON.stringify(j);
        },
      }
      var key = this.$route.query.order_id;

      var emi_str = this.editedItem.emi;
      var wordpos = emi_str.search("months");
      var emi_month = emi_str.substring(0, wordpos-1);
      var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
      let selectedCardStatus = this.isSelectedCard;

      if(selectedCardStatus === true){

        var sp_login_status = this.getCookie("token");
        var engine_token = this.$route.query.token;
        var orderid = this.$route.query.order_id;
        //var amount = this.sp_amount;
        var amount;
        var cof_payable_amt = this.payable_amt;
        if(cof_payable_amt>0){
          amount = cof_payable_amt;
        }
        else{
          amount = this.sp_amount;
        }

        var card_number1 = this.editedItem.card_number;
        var card_number_space_remove = card_number1.replace(/ /g, "");
        var card_number = CryptoJS.AES.encrypt(JSON.stringify(card_number_space_remove),key,{format: CryptoJSAesJson}).toString();

        var expdate1 = this.editedItem.mm_yy;
        var expdate = CryptoJS.AES.encrypt(JSON.stringify(expdate1),key,{format: CryptoJSAesJson}).toString();

        var cvv_cvc1 = this.editedItem.cvc_cvv;
        var cvv_cvc = CryptoJS.AES.encrypt(JSON.stringify(cvv_cvc1),key,{format: CryptoJSAesJson}).toString();
        
        var card_holder_name1 = this.editedItem.card_holder_name;
        var card_holder_name = CryptoJS.AES.encrypt(JSON.stringify(card_holder_name1),key,{format: CryptoJSAesJson}).toString();

        var card_name = this.user_entered_card_name;
        var is_save_card;
        var store_id = this.store_id;
        
        if(this.checkbox == true){
          is_save_card ='1';
        }
        else{
          is_save_card ='0';
        }


        /*
        **if the user is not logged in then....
        **isLoggedin value is null
        **mobile_no value is null
        */
        if(sp_login_status === "" || sp_login_status !== "" && is_save_card == 0){

          //New changes 01/09/2021
          var c_token = this.getCookie("token");
          if(c_token!==""){
            customer_token = c_token;
          }
          else{
            customer_token = "";
          }

          const options = {
            method: 'POST',
            url: this.base_url+'pay-now',
            headers: {
              Authorization: 'Bearer '+ engine_token
            },
            data: {
              card_number,
              expdate,
              cvv_cvc,
              card_holder_name,
              card_name,
              orderid,
              amount,
              is_save_card,
              store_id,
              customer_token
            },
            
            };

            axios.request(options).then(response =>{
              if(response.data.original.sp_code == '200'){
                window.location.href = response.data.original.url;
              }

              else if(response.data.original.sp_code == '424'){
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = response.data.original.message;
              }
              else if(response.data.original.sp_code == '104'){
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text =  response.data.original.message;
              }

              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = "Something went wrong";
              }

              }).catch(error =>{
                console.log(error);
              });
        }
        //sp_login_status null if end


        /*
        * when sp_login_status is set 
        */
        
        if(sp_login_status !== "" && is_save_card==1){
          var customer_token = this.getCookie("token");
          const options = {
            method: 'POST',
            url: this.customer_api_base_url+'save-and-pay-now',
            headers: {
              Authorization: 'Bearer '+ customer_token
            },
            data: {
              card_number,
              expdate,
              cvv_cvc,
              card_holder_name,
              card_name,
              orderid,
              amount,
              is_save_card,
              store_id,
              engine_token,
            },
            
            };

          axios.request(options).then(response =>{
            if(response.data.sp_code == '200'){
              this.snackbar = true;
              this.color = "#0F9750";
              this.timeout = 2000;
              this.popup_text = "Card saved successful.";
              window.location.href = response.data.url;
            }

            else if(response.data.sp_code == '424'){
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text = response.data.message;
            }
            else if(response.data.sp_code == '104'){
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text =  response.data.message;
            }

            else{
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text = "Something went wrong";
            }

            }).catch(error =>{
              console.log(error);
            });  
        }
        //sp_login_status is not null if end

      } //selectedCardStatus if end

      else if(selectedCardStatus === false){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Enter a Valid Card number";
        this.editedItem.card_number="";
      }  
    },

    openCardForm(){
      this.saved_card_tab1 = true;
      this.card_tab = false;
    },

    /**
     * Cards Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfoCards(card_name,store_id,gateway_id,gateway_type_id){
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'get-tdr-charge',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data: {
          store_id,
          gateway_id
          },
        };
        axios.request(options).then(response =>{
          
          var perchange  = response.data[0].tdr_charge;
          
          if(perchange!=0){
            
            var amount_initiate = Number(this.sp_amount);
            var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
            this.additional_fees_amt = (additional_fees_amount).toFixed(2);
            var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
            this.payable_amt = payable_amount_bdt;

            this.tdr_visibility= this.is_add_commission;
            this.tdr_visibility_pay_now_btn = this.is_add_commission;
            this.normal_user = 0;

            this.gateway_type_id_tdr = gateway_type_id;
            this.card_name_tdr = card_name;

          }
          else{
            //console.log("No TDR set for that gateway...");
            this.tdr_visibility= 0;
            this.tdr_visibility_pay_now_btn = 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.gateway_type_id_tdr = gateway_type_id;
            this.goDirectCardsGateWay(card_name);
          }
          
        }).catch(error =>{
            console.log(error);
        }); 
    },
    
    ForwardCardGateway(card_name,gateway_id,gateway_type_id){
      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;
      if(is_add_commission_tdr==1)
      {
        //console.log("is_add_commission enabled");
        this.tdrComissionInfoCards(card_name,store_id,gateway_id,gateway_type_id);
      }
      else
      {
       //console.log("No is_add_commission enabled");
       this.goDirectCardsGateWay(card_name);
      }
    },

    goDirectCardsGateWay(card_name){
      if(card_name === ""){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 6000;
          this.popup_text = "No card name is found.";
      }
      if(card_name !== ""){

        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }

        if(amount<10){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 3000;
          this.popup_text = "Amount Cannot be less than 10 Taka.";
        }
        else{
          this.loading_tab = true;
          this.loading_dialog = true;
          var store_id = this.store_id;
          var orderid = this.$route.query.order_id;
          var emi_str = this.editedItem.emi;
          var wordpos = emi_str.search("months");
          var emi_month = emi_str.substring(0, wordpos-1);
          var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
          var sp_token = this.$route.query.token;
          var invoiceno = this.invoiceno;
          const options = {
              method: 'POST',
              url: this.base_url+'only-card-pay',
              headers: {
                Authorization: 'Bearer '+ sp_token
              },
              data:{
                card_name,
                store_id,
                orderid,
                emi_month,
                emi_amt,
                invoiceno
              },
            };
              axios.request(options).then(response =>{
                if(response.data.message==1){
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  window.location.href = response.data.url;
                }
                else{
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 3000;
                  this.popup_text = "Something went wrong";
                }
              }).catch(error =>{
                console.log(error);
              }); 
         }
      }
    },

    goCardGateway(card_name,gateway_id,gateway_type_id){
     this.cards_selected_gateway_iddd = gateway_id;
     this.sp_orderid = this.$route.query.order_id;
     this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined')
      {
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.isMaintenanceMode = false;
      }

      else
      {
          const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };

            axios.request(options).then(response =>{
              if(response.data.sp_code== '200'){  
                  this.ForwardCardGateway(card_name,gateway_id,gateway_type_id);
              }
              
              else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
              }
              
              }).catch(error =>{
                if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    this.isMaintenanceMode = false;
                    setTimeout(this.CancelURL, 500);
                  }
            });
        }
    },


    //later
    tabIndex(index){
      window.localStorage.setItem('sp_active_tab',index);
    },

   
    active_tabs(index){

      if(index==1)
      {
        this.CheckLoginFunction();

        if(this.loginBool==1 && this.is_card_on_file_active==1){
            this.SavedcardsAction();
            this.active_tab = 'cards';
            this.cardSkeletonLoader = false;
        }
        else if(this.loginBool==0 && this.is_card_on_file_active==1){
           this.show_cards(1);
           this.active_tab = 'cards';
           this.cardSkeletonLoader = false;
        }
        else{
            this.show_cards(1);
            this.active_tab = 'cards';
            this.cardSkeletonLoader = false;
        }
      }

      else if(index==2){
        this.active_tab = 'mfs';
        this.cardSkeletonLoader = false;
        this.show_mbanking();
      }
      else if(index==3){
        this.active_tab = 'ibanking';
        this.show_ibanking();
        this.cardSkeletonLoader = false;
      }
      else{
        this.active_tab = 'cards';
        this.show_account();
        this.cardSkeletonLoader = false;
      }
    },

    //checked when clicked
    BKASH(){

      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      
      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'bkash-pay-now',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
             this.loading_tab = false;
             this.loading_dialog = false;
             window.location.href = response.data.burl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
        }

    },

    //no need
    closePopup(){
      this.popup_dialog =false;
      this.$refs.form3.reset();
    },

    //checked when clicked
    send_bkh(){
      let checkVal= this.$refs.form3.validate();
      var agreement_cancle= this.agreementStatus;
      
      if(checkVal){
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;

        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }
        var mobile_no = this.others.mobile_number;
        var mobile_number = localStorage.getItem('mobile_no');
        var bankPara = "bkh";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        

        if(mobile_number===null){
          const options = {
            method: 'POST',
            url: this.base_url+ 'bkash-pay-now',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              mobile_no,
              store_id,
              invoiceno,
              agreement_cancle
            },
            
            };
            axios.request(options).then(response =>{
              window.location.href = response.data.burl;
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            }).catch(error =>{
              console.log(error);
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            });
        }

        if(mobile_number !==null){
            const options = {
            method: 'POST',
            url: this.base_url+ 'bkash-pay-now',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              mobile_no,
              mobile_number,
              store_id,
              invoiceno,
              agreement_cancle
            },
            
            };
            axios.request(options).then(response =>{
              window.location.href = response.data.burl;
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            }).catch(error =>{
              console.log(error);
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            });
        }

      }
    },

   //checked when clicked
    NAGAD(){
      var payable_amount2 = this.payable_amt;
      var amt;

      if(payable_amount2>0){
        amt = payable_amount2;
      }
      else{
        amt = this.sp_amount;
      }
      

      if(amt<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'nagad-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amt,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
             this.loading_tab = false;
             this.loading_dialog = false;
             window.location.href = response.data.nurl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
      }

    },

    //checked when clicked
    MCASH(){
      var payable_amount2 = this.payable_amt;
      var amt;

      if(payable_amount2>0){
        amt = payable_amount2;
      }
      else{
        amt = this.sp_amount;
      }
     

      if(amt<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;

        const options = {
          method: 'POST',
          url: this.base_url+'mcash-pay-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            amt,
            orderid,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.mburl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
      }
      
    },
    
    //checked when clicked
    TCASH(){

      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        var card_name ="tap";  
        const options = {
          method: 'POST',
          url: this.base_url+'only-card-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            amount,
            orderid,
            store_id,
            invoiceno,
            card_name
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.url;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

    //checked when clicked
    UPAY(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }


      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
          this.loading_tab=true;
          this.loading_dialog=true;
          var orderid = this.$route.query.order_id;
          
          var bankPara = "upay";
          var invoiceno = this.invoiceno;
          var store_id = this.store_id;
          const options = {
            method: 'POST',
            url: this.base_url+'upay-payment',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              store_id,
              invoiceno
            },

            };
            axios.request(options).then(response =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              window.location.href = response.data.upurl;
            }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
            });
        }
     
    },
    
    //checked when clicked
   SHURECASH(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var bankPara = "scash";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;

        const options = {
          method: 'POST',
          url: this.base_url+'sure-cash-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            bankPara,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.scurl;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

  //checked when clicked
  OKWALLET(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var bankPara = "ok-wallet";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'ok-wallet-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            bankPara,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.ourl;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

//checked when clicked
  ROCKET(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<10){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 10 Taka.";
      }

      else{
        this.loading_tab = true;
        this.loading_dialog = true;
        var orderid = this.$route.query.order_id;
        var store_id = this.store_id;
        var card_name = "rocket";
        var invoiceno = this.invoiceno;

        const options = {
          method: 'POST',
          url: this.base_url+'only-card-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            store_id,
            card_name,
            amount,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            if(response.data.message == 1){
               this.loading_tab = false;
               this.loading_dialog = false;
                window.location.href = response.data.url;
            }
            else{
                this.loading_tab = false;
                this.loading_dialog = false;
                this.snackbar = true;
                this.color = "red";
                this.timeout = 3000;
                this.popup_text = "Something went wrong.";
            }
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            console.log(error);
          });
      }  
  },

    //checked when clicked
    show_cards(from_where_call){

        this.showCreateAccountForm = false;
        this.showCOFRegisterOTPForm = false;
        this.is_cof_form_enable = true;
        this.is_cof_form_enable_terms = true;
        this.is_cof_form_enable_btn = true;
        this.is_cof_form_enable_btn2 = false;
        this.is_mask_card_enable = false; 
        this.isCheckLoginEnable = true;
        this.isFormValidNew = false;
        this.AccountActiveDeactiveDiv = false;
       
        //New changes
        var card_number_without_space = this.editedItem.card_number.replace(/\s/g, '');
        if(card_number_without_space.length>=6){
          this.CheckCardOnFileTdr();
        }
        this.checkbox = false;
        this.isCOFLoginFormEnable = false;

        this.isFormValid2 = false;
        this.CheckLoginFunction();
        var passStoreID = this.store_id;
        this.cardSkeletonLoader = false;
        this.mobile_banking = false;
        this.i_banking = false;
        this.user_account = false;
        this.loading_tab = false;
        this.loading_dialog = false;
        this.tdr_visibility = 0;
        this.tdr_visibility_pay_now_btn = 0;
        this.normal_user = 1;
        this.payable_amt = 0;
        this.cof_pay_now_btn_label ="Pay Now";
        this.saved_card_paynow_label = "Pay Now";
        

        this.merchant_gateway(1);
        this.cards_selected_gateway_iddd=0;
        this.mfs_selected_gateway_iddd=0;
        this.ibanking_selected_gateway_iddd=0;

        if(this.loginBool == 1 && this.is_card_on_file_active==1){

          var total_saved_card =  this.cardList.length;

          if(total_saved_card>0){

            if(from_where_call=='2'){
              this.card_tab = false;
              this.other_cards_display = false;
              this.saved_card_list = false;
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;

              this.hideCard_div = true;
              this.hidecardmenu = true;
              this.hideshowpaynow = true;
              this.saved_card_tab1 = true;
              this.fastPayStatus = true;
              this.saved_card_section = false;
            }
            else{
            this.saved_card_list = true;
            this.hideCard_div = false;
            this.hidecardmenu = false;
            this.hideshowpaynow = false;
            this.other_cards_display = false;


            this.cardList = "";
            var customer_token = this.getCookie("token");

            if(customer_token !== ""){
              const options = {
                method: 'GET',
                url: this.customer_api_base_url+'show-card-info-list',
                headers: {
                    Authorization: 'Bearer '+customer_token
                },
              
              };

              axios.request(options).then(response =>{
                // When the response is success
                if(response.data.sp_code == '200'){
                  this.cardList = response.data.card_data;
                  this.isShowStaticPart = true;
                }

                // When no card data saved
                else if(response.data.sp_code == '204'){
                  this.show_cards(1);
                  this.isShowStaticPart = false;
                }

                // Authentication problem
                else if(response.data.sp_code == '1064'){
                  this.show_cards(1);
                  this.snackbar = true;
                  this.color = "error";
                  this.timeout = 2000;
                  this.popup_text = "Authentication Problem";
                  this.isShowStaticPart = false;
                }

                else{
                    this.show_cards(1);
                    this.isShowStaticPart = false;
                }
              
              }).catch(error =>{
                console.log(error);
              });

            }
            }
          }
          
         else{
            this.card_tab = false;
            this.other_cards_display = false;
            this.saved_card_list = false;
            this.deleteCardConfirmationDialog = false;
            this.deleteConfirmationCard = false;

            this.hideCard_div = true;
            this.hidecardmenu = true;
            this.hideshowpaynow = true;
            this.saved_card_tab1 = true;
            this.fastPayStatus = true;
            this.saved_card_section = false;
          }
        }

        else if(this.loginBool == 0 && this.is_card_on_file_active==1){
          this.card_tab = false;
          this.other_cards_display = false;
          this.saved_card_list = false;
          this.deleteCardConfirmationDialog = false;
          this.deleteConfirmationCard = false;

          this.hideCard_div = true;
          this.hidecardmenu = true;
          this.hideshowpaynow = true;
          this.saved_card_tab1 = true;
          this.fastPayStatus = true;
          this.saved_card_section = false;
        }

        else{
          
          this.card_tab = true;
          this.other_cards_display = false;

          this.saved_card_list = false;
          this.deleteCardConfirmationDialog = false;
          this.deleteConfirmationCard = false;

          this.hideCard_div = false;
          this.hidecardmenu = false;
          this.hideshowpaynow = false;
          this.saved_card_tab1 = false;
          this.fastPayStatus = false;
          this.saved_card_section = false;
        }
        
    },

     //checked when clicked
    show_mbanking(){
      this.card_tab=false;
      this.mobile_banking=true;
      this.i_banking=false;
      this.user_account=false;
      this.loading_tab=false;
      this.loading_dialog=false;
      this.saved_card_tab1 =false;
      var passStoreID = this.store_id;
      
      this.tdr_visibility= 0;
      this.tdr_visibility_pay_now_btn = 0;
      this.normal_user = 1;
      this.payable_amt = 0;

      this.cof_pay_now_btn_label ="Pay Now";
      this.saved_card_paynow_label = "Pay Now";
      this.isFormValid2 = false;

      this.merchant_gateway(2);

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;
      this.saved_card_list = false;
      this.deleteCardConfirmationDialog = false;
      this.deleteConfirmationCard = false;
      this.cardSkeletonLoader = false;
      this.AccountActiveDeactiveDiv = false;
    },

     //checked when clicked
    show_ibanking(){
      this.card_tab=false;
      this.mobile_banking=false;
      this.i_banking=true;
      this.user_account=false;
      this.loading_tab=false;
      this.loading_dialog=false;
      this.saved_card_tab1 =false;
      var passStoreID = this.store_id;

      this.tdr_visibility= 0;
      this.tdr_visibility_pay_now_btn = 0;
      this.normal_user = 1;
      this.payable_amt = 0;
      this.cof_pay_now_btn_label ="Pay Now";
      this.saved_card_paynow_label = "Pay Now";
      this.isFormValid2 = false;

      this.merchant_gateway(3);

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;
      this.saved_card_list = false;
      this.deleteCardConfirmationDialog = false;
      this.deleteConfirmationCard = false;
      this.cardSkeletonLoader = false;
      this.AccountActiveDeactiveDiv = false;
    },

   
    //Token implemented
    show_account(){
      this.AccountActiveDeactiveDiv = false;
      this.resultsMobile = {};
      this.cardSkeletonLoader = false;
      this.deleteCardConfirmationDialog = false;
      this.deleteConfirmationCard = false;
      this.saved_card_list = false;
      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;

      this.cof_pay_now_btn_label ="Pay Now";
      this.saved_card_paynow_label = "Pay Now";
      this.isFormValid2 = false;

      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.card_tab=false;
                  this.mobile_banking=false;
                  this.i_banking=false;
                  this.loading_tab=false;
                  this.loading_dialog=false;
                  this.saved_card_tab1 =false;
                  this.signup_div= false;
                  this.user_account=true;
                  this.login_div = true;

                  this.tdr_visibility= 0;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;

                  if(this.myaccount == true){
                    this.login_div = false;
                    this.myaccount = true;
                  }
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //card-holder-login check api
    login_status(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.login_status_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },
    
    login_status_pre(){
       let check_login_val= this.$refs.form5.validate();
       
       if(this.resultsMobile["isValid"]){

        if(check_login_val){

         var countryCode = this.resultsMobile["countryCode"];
         var formattedNumber = this.resultsMobile["formattedNumber"];
        
         var token = this.$route.query.token;
         this.loginItem.login_mobile_number = formattedNumber;
         var mobile_no = formattedNumber;
         var password_real = this.loginItem.login_password;

        var CryptoJSAesJson = {
          stringify: function (cipherParams) {
            var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
          },
        }

        var key = this.$route.query.order_id;
        var orderid = this.$route.query.order_id;
        var password = CryptoJS.AES.encrypt(JSON.stringify(password_real),key,{format: CryptoJSAesJson}).toString();

          const options = {
            method: 'POST',
            url: this.customer_api_base_url+'customer-login',
            headers: {
              Authorization: 'Bearer '+token
            },
            data: {
              mobile_no,
              password,
              countryCode,
              orderid
            },
          };

          axios.request(options).then(response =>{

            //When the user is active and successful login
            if(response.data.is_active == 1 && response.data.sp_code == '200'){
              this.customer_name = response.data.customer_name;
              this.login_div = false;
              this.myaccount= true;
              this.no_login=false;
              this.loginBool = 1;

              //Set cookie information
              var cookie_name = "token";
              var cookie_value = response.data.token;
              var exipry_from_token = response.data.expires_in;
              let date = new Date();
              date.setTime(date.getTime() + (exipry_from_token * 1000));
              let cookie_time = "expires=" + date.toUTCString();
              document.cookie = cookie_name + "=" + cookie_value + "; " + cookie_time + "; ";
              
              //Reset login form data
              this.loginItem.login_mobile_number="";
              this.loginItem.login_password="";

              //After login fetch saved card list (if any)
              this.cardList = "";
              var customer_token = this.getCookie("token");

              if(customer_token !== ""){
                const options = {
                  method: 'GET',
                  url: this.customer_api_base_url+'show-card-info-list',
                  headers: {
                      Authorization: 'Bearer '+customer_token
                  },
                };

                axios.request(options).then(response =>{
                  if(response.data.sp_code == '200'){
                    this.cardList = response.data.card_data;
                    this.isShowStaticPart = true;

                    //After login redirect tab decision
                    if(this.isCard == 1){
                      this.active_tab = 'cards';
                      this.show_cards(1);
                    }

                    if(this.isCard == 0 && this.isMbanking == 2){
                      this.active_tab = 'mfs';
                      this.show_mbanking();
                    }

                    if(this.isCard == 0 && this.isMbanking == 0 && this.isIBanking == 3){
                      this.active_tab = 'ibanking';
                      this.show_ibanking();
                    }

                   
                  }
                  else{
                    this.isShowStaticPart = false;

                    //After login redirect tab decision
                    if(this.isCard == 1){
                      this.active_tab = 'cards';
                      this.show_cards(1);
                    }

                    if(this.isCard == 0 && this.isMbanking == 2){
                      this.active_tab = 'mfs';
                      this.show_mbanking();
                    }

                    if(this.isCard == 0 && this.isMbanking == 0 && this.isIBanking == 3){
                      this.active_tab = 'ibanking';
                      this.show_ibanking();
                    }
                  }

                }).catch(error =>{
                  console.log(error);
                });

              }
              else{
                //After login redirect tab decision
                if(this.isCard == 1){
                  this.active_tab = 'cards';
                  this.show_cards(1);
                }

                if(this.isCard == 0 && this.isMbanking == 2){
                  this.active_tab = 'mfs';
                  this.show_mbanking();
                }

                if(this.isCard == 0 && this.isMbanking == 0 && this.isIBanking == 3){
                  this.active_tab = 'ibanking';
                  this.show_ibanking();
                }
              }

            }

            //Password not matched
            else if(response.data.sp_code == '1064'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 6000;
              this.popup_text = response.data.message;
            }

            //No such account exist,Wrong Mobile number
            else if(response.data.sp_code == '102'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 6000;
              this.popup_text = response.data.message;
             }

            //Account is not active popup account active modal
            else if(response.data.is_active == 0 && response.data.sp_code == '200'){
              this.login_div=true;
              this.active_acc_otp = true;
              this.display_resend_otp_text = true;
              this.dispaly_time_otp = false;
              this.active_account_mobile = formattedNumber;
             }
          
          }).catch(error =>{
              console.log(error);
              this.snackbar = true;
              this.color = "red";
              this.popup_text = "Something went wrong...";
          });
          
        }

        else{
          this.snackbar = true;
          this.color = "error";
          this.timeout = 2000;
          this.popup_text = "Please provide password field";
        }

       }
       else{
          this.snackbar = true;
          this.color = "error";
          this.timeout = 2000;
          this.popup_text = "Mobile number is not valid";
       }
      
    },


    getCookie(cname) {
      let name = cname + "=";
      let spli = document.cookie.split(';');
      for(var j = 0; j < spli.length; j++) {
        let char = spli[j];
        while (char.charAt(0) == ' ') {
          char = char.substring(1);
        }
        if (char.indexOf(name) == 0) {
          return char.substring(name.length, char.length);
        }
      }
      return "";
    },

    //checked during mounted
    check_card_holder_login(){

      var customer_token = this.getCookie("token");

      if(customer_token !==""){
          //console.log("Token has value");
          const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-token-check',
          headers: {
            Authorization: 'Bearer '+customer_token
          },
        };
        axios.request(options).then(response =>{
          if(response.data.sp_code == '200'){
            this.login_div = false;
            this.myaccount= true;
            this.no_login=false;
            this.customer_name = response.data.customer_name;
            this.loginBool = 1;
          }
          else{
            this.login_div = true;
            this.myaccount= false;
            this.no_login=true;
          }
          
        }).catch(error =>{
            console.log(error);
        });
      }
    },

    //token applied
    register(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.register_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    register_pre(){

       let signup_val= this.$refs.form_register.validate();

       if(this.resultsMobile["isValid"]){

           if(signup_val){
            var countryCode = this.resultsMobile["countryCode"];
            var formattedNumber = this.resultsMobile["formattedNumber"];
           
            var email = this.signup_items.email_address;
            var token = this.$route.query.token;
            var name = this.signup_items.fullname;
            var password = this.signup_items.password;
            var password_confirmation = password;
            // var mobile_no = this.signup_items.mobile_number;
            this.signup_items.mobile_number = formattedNumber;
            var mobile_no = formattedNumber;

            const options = {
              method: 'POST',
              url: this.customer_api_base_url+'customer-sign-up',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                name,
                email,
                password,
                password_confirmation,
                mobile_no,
                countryCode
              },
              
              };
              axios.request(options).then(response =>{
                
                if(response.data.sp_code == '200'){
                    this.signup_div = false;
                    this.login_div = true;
                    this.resigtration_otp = true;
                    // var inputed_mobile = this.signup_items.mobile_number;
                    this.signup_items.mobile_number = formattedNumber;
                    var inputed_mobile = formattedNumber;
                    this.resigter_otp_text = inputed_mobile;
                    this.showRemaining(3);
                }

                else{
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 7000;
                  this.popup_text = response.data.message;
                }

              }).catch(error =>{
                console.log(error);
                this.snackbar = true;
                this.color = "red";
                this.timeout = 6000;
                this.popup_text = "Something went wrong...";
              });
          }
          else{
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text = "Provide required fields value";
          }

       }

       else{
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Mobile Number is not valid";
       }
       
      
    },


    //token applied self
    register_otp_confirm(){
       let check_otp_varification= this.$refs.register_otpp.validate();
       if(check_otp_varification){
        var token = this.$route.query.token;
        var mobile_no = this.signup_items.mobile_number;
        var verify_code = this.resigter_otp;

         const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-verify-account',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
            mobile_no,
            verify_code,
          },
          
          };

           axios.request(options).then(response =>{
            if(response.data.sp_code == '111'){
                this.snackbar = true;
                this.color = "#0F9750";
                this.popup_text = response.data.message;
                this.login_div=true;
                this.signup_div=false;
                this.resigtration_otp = false;

                //Reset sign up form
                this.signup_items.email_address = "";
                this.signup_items.fullname = "";
                this.signup_items.password = "";
                this.signup_items.mobile_number = "";

            }
            else{
               this.snackbar = true;
               this.color = "red";
               this.popup_text = response.data.message;
            }
           
           
          }).catch(error =>{
             console.log(error);
          });
       }
    },
   
    //token applied
    close_reg_otp_modal(){
       this.sp_orderid = this.$route.query.order_id;
        this.sp_token = this.$route.query.token;

        if(typeof(this.sp_token)==='undefined'){
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.isMaintenanceMode = false;
        }

      else{
          const options = {
              method: 'POST',
              url: this.base_url+'check-token',
              headers: {
                Authorization: 'Bearer '+this.sp_token
              }
            };
              axios.request(options).then(response =>{
                  if(response.data.sp_code== '200'){  
                    this.signup_div = true;
                    this.login_div = false;
                    this.resigtration_otp = false;
                  }
                
                  else if(response.data.sp_code== '1064'){
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    this.isMaintenanceMode = false;
                    setTimeout(this.CancelURL, 500);
                  }
                
              }).catch(error =>{
                  if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    this.isMaintenanceMode = false;
                    setTimeout(this.CancelURL, 500);
                    }
            });
        }
    },

    //token applied self
    resend_otp(){
        var token = this.$route.query.token;
        var mobile_no = this.signup_items.mobile_number;
         const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-resend-code',
          headers: {
            Authorization: 'Bearer '+token
          },
          data:{
            mobile_no
          },
          };

           axios.request(options).then(response =>{
            if(response.data.sp_code == '200'){
              this.snackbar = true;
              this.color = "success";
              this.timeout = 7000;
              this.popup_text = "Another OTP sent to " + this.signup_items.mobile_number;

              this.login_div=true;
              this.signup_div=false;

              this.resigtration_otp = true;
              this.dispaly_time_otp = true;
              this.display_resend_otp_text =false;
              this.showRemaining(3); 
            }
            else{
               this.snackbar = true;
               this.color = "red";
               this.popup_text = response.data.message;
            }

           
          }).catch(error =>{
             console.log(error);
          });
    },

    //later
    cancel_btn(){
        // var token = this.$route.query.token;
        // var orderid = this.$route.query.order_id;
        // const options = {
        //   method: 'POST',
        //   url: this.base_url+'cancle',
        //   headers: {
        //     Authorization: 'Bearer '+token
        //   },
        //   data:{
        //     orderid
        //   },
        // };
        // axios.request(options).then(response =>{
          
        //   if(response.data.message == 1){
        //       window.location.href = response.data.url;
        //   }

        //   else{
        //     this.snackbar = true;
        //     this.color = "red";
        //     this.popup_text = "Something went wrong...";
        //    }
        // }).catch(error =>{
        //   console.log(error);
        // });

        this.saved_card_tab1 = true;
        this.card_tab = false;

     },

    
    //token applied
    show_singup_form(){
      this.resultsMobile = {};
     
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                   this.login_div=false;
                   this.signup_div=true;
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //no need --never used
    show_login_form(){
      this.login_div=true;
      this.signup_div=false;
    },

   //later
    othersCard(){

      var enable_other_card_list = [];
      var total_cards_gateway = this.cards_gateways.length;

      for (let i = 0; i < total_cards_gateway; i++) {
        if(this.cards_gateways[i].gateway_id == 11 ||this.cards_gateways[i].gateway_id==12 ||this.cards_gateways[i].gateway_id==13){
          enable_other_card_list.push(this.cards_gateways[i]);
        }
      }
      
      for (let i = 0; i < total_cards_gateway; i++) {
        if(this.cards_gateways[i].gateway_id == 6){
          enable_other_card_list.push(this.cards_gateways[i]);
        }
      }
      
      this.selected_other_card_list = enable_other_card_list;

      this.is_cof_form_enable_btn2=false;
      this.is_mask_card_enable=false;
      this.isCOFLoginFormEnable=false;

      this.other_cards_display=true;
      this.hideCard_div=false;
      this.hidecardmenu=false;
      this.hideshowpaynow=false;
      this.AccountActiveDeactiveDiv = false;
    },

    //token applied
    forgot_password(){
      this.resultsMobile = {};
      
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.forgot_password_modal = true;
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },


    //token applied
    send_otp(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
        this.isMaintenanceMode = false;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'check-token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.sp_code== '200'){  
                  this.send_otp_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  this.isMaintenanceMode = false;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   this.isMaintenanceMode = false;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    send_otp_pre(){
      //let isValid = this.$refs.form2.validate();
      let isValid = this.resultsMobile["isValid"];

      if(isValid){

        var countryCode = this.resultsMobile["countryCode"];
        var formattedNumber = this.resultsMobile["formattedNumber"];
       
        //var mobile_no = this.forgot_password_mobile;
        this.forgot_password_mobile = formattedNumber;
        var mobile_no = formattedNumber;
        var token_check = this.$route.query.token;
        
        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-forgot-pass',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no,
            countryCode
          }
        };

        axios.request(options).then(response =>{
        if(response.data.sp_code == '200'){
          this.forgot_password_modal = false;
          this.forgot_password_otp_modal = true;

          // var inputed_mobile = this.forgot_password_mobile;
          var inputed_mobile = formattedNumber;
          this.otp_send_text_msg = inputed_mobile;
        }

        else{
            this.snackbar = true;
            this.color = "red";
            this.popup_text = response.data.message;
        }
        
      }).catch(error =>{
          console.log(error);
      });

      }

      else{
        this.snackbar = true;
        this.color = "red";
        this.timeout = 2000;
        this.popup_text = "Mobile number is not valid";
      }
    },
    

    //token applied self
    send_active_otp(){
      let isValid = this.$refs.form22.validate();
      if(isValid){
        var mobile_no = this.active_account_mobile;
        var token_check = this.$route.query.token;
        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-verify-account',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no
          },
        };

        axios.request(options).then(response =>{
        if(response.data.sp_code == '111'){

          //this.active_account_modal=false;
          //this.active_acc_otp=true;
          //var inputed_mobile = this.active_account_mobile;
          // var f_part = inputed_mobile.substring(0,3);
          // var first_part = inputed_mobile.substring(3, 9);
          // var hidden_part = first_part.replace(/[0-9]/g, "*");
          // var normal_part = inputed_mobile.substring(9, 11);
         // this.active_account_otp_input_text = inputed_mobile;
         
         this.active_acc_otp = false;
         this.login_div = true;
         this.signup_div = false;
          
        }

       
        else{
            this.snackbar = true;
            this.color = "red";
            this.popup_text = response.data.message;
        }
        
      }).catch(error =>{
          console.log(error);
      });

      }
    },

  //token applied self
    active_otp_confirm(){

      let check_otp_varification= this.$refs.active_otpp.validate();

      if(check_otp_varification){
      var token = this.$route.query.token;
      var mobile_no = this.active_account_mobile;
      var verify_code = this.active_account_otp_input;

        const options = {
        method: 'POST',
        url: this.customer_api_base_url+'customer-verify-account',

        headers: {
          Authorization: 'Bearer '+token
        },
        data: {
          mobile_no,
          verify_code,
        },
        
        };

          axios.request(options).then(response =>{
          if(response.data.sp_code == '111'){
              this.snackbar = true;
              this.color = "#0F9750";
              this.timeout=8000;
              this.popup_text = "Account Activation successful.";
              
              this.login_div=true;
              this.signup_div=false;
              this.active_acc_otp = false;
              this.loginItem.login_mobile_number="";
              this.loginItem.login_password="";
          }
          
          else{
            this.snackbar = true;
            this.color = "red";
            this.timeout = 4000;
            this.popup_text = response.data.message;
          }
          
        }).catch(error =>{
            console.log(error);
        });
      }

      else{
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000
        this.popup_text = "Enter Your OTP From SMS";
      }
    },

       //token applied
       send_otp_back(){
          this.sp_orderid = this.$route.query.order_id;
          this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
            this.isMaintenanceMode = false;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'check-token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.sp_code== '200'){  
                      this.forgot_password_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },

       //Applied via self called
       submit_otp(){
          let isValid = this.$refs.form_otp.validate();
          if(isValid){
            var token = this.$route.query.token;
            var verify_code = this.forgot_password_otp;
            var mobile_no = this.forgot_password_mobile;
             const options = {
              method: 'POST',
              url: this.customer_api_base_url+'customer-verify-account',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                  verify_code,
                  mobile_no
              },
              
            };

            axios.request(options).then(response =>{
              if(response.data.sp_code == '111'){
                this.forgot_password_otp_modal = false;
                this.forgot_password_modal = false;
                this.new_password_modal = true;
                this.reset_password_mobile = mobile_no;
              }
              else if(response.data.sp_code == '101'){
                this.snackbar = true;
                this.color = "red";
                this.timeout=3000;
                this.popup_text = "OTP does not matched";
                this.reset_password_mobile = mobile_no;
              }
              else if(response.data.sp_code =='103'){
                this.snackbar = true;
                this.color = "red";
                this.timeout = 200;
                this.popup_text = response.data.message;
                this.reset_password_mobile = mobile_no;
              }
              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout = 200;
                this.popup_text = response.data.message;
                this.reset_password_mobile = mobile_no;
              }
            
            }).catch(error =>{
              console.log(error);
              this.reset_password_mobile = mobile_no;
            });
             
          }
       },

       //token applied
       submit_otp_back(){
        
         this.sp_orderid = this.$route.query.order_id;
         this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
            this.isMaintenanceMode = false;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'check-token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.sp_code== '200'){  
                       this.forgot_password_otp_modal = false;
                       this.forgot_password_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },


      //token applied by self
       resetPassword(){
         let checkVal = this.$refs.form_password_reset.validate();
         if(checkVal){

           var token = this.$route.query.token;
           var mobile_no = this.reset_password_mobile;
           var c_password = this.confirmed_new_password;
           
           const options = {
              method: 'POST',
              url: this.customer_api_base_url+'customer-new-pass-set',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                mobile_no,
                c_password,
              },
              
            };

            axios.request(options).then(response =>{
            if(response.data.sp_code == '200'){
                this.forgot_password_otp_modal=false;
                this.forgot_password_modal=false;
                this.new_password_modal = false;
                //mominur

                this.signup_div= false;
                this.user_account=true;
                this.login_div = true;

                this.snackbar = true;
                this.color = "primary";
                this.timeout = 3000;
                this.popup_text = "Your password has been changed successfully.";
            }
           
            else{
              this.snackbar = true;
              this.color = "primary";
              this.timeout = 3000;
              this.popup_text = response.data.message;
            }
           
          }).catch(error =>{
             console.log(error);
             this.$refs.form2.reset();
          });

         }
       },
      
       //token applied
       resetPassword_back(){
        
         this.sp_orderid = this.$route.query.order_id;
         this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
            this.isMaintenanceMode = false;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'check-token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.sp_code== '200'){  
                       this.new_password_modal = false;
                       this.forgot_password_otp_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      this.isMaintenanceMode = false;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },


       logout_user(){

          var customer_login_cookie = this.getCookie("token");

          if(customer_login_cookie !== ""){
            var cookie_name = "token";
            var cookie_value = "";
            let date = new Date();
            date.setTime(date.getTime() - (2 * 1000));
            let cookie_time = "expires=" + date.toUTCString();
            document.cookie = cookie_name + "=" + cookie_value + "; " + cookie_time + "; ";

            this.loginBool = 0;
            this.cardList = "";

            this.myaccount = false;
            this.no_login = true;
            this.login_div = true;

            this.isShowStaticPart = false;

            // New changes
            // 29/08/2022

            if(this.logout_tab_decision == "1"){
              this.active_tab = 'cards';
              this.show_cards(1);
            }

            if(this.logout_tab_decision == "2"){
              this.active_tab = 'mfs';
              this.show_mbanking();
            }

            if(this.logout_tab_decision == "3"){
              this.active_tab = 'ibanking';
              this.show_ibanking();
            }

            if(this.logout_tab_decision == "4"){
              this.active_tab = 'account';
              this.show_account();
            }

          }

          else{
            this.snackbar = true;
            this.color = "error";
            this.timeout = 2000;
            this.popup_text = "No Cookie Exists";
          }
         
       },

      
      //no need
      SavedcardsAction(){
          this.saved_card_list = true;
          this.getSavedCardList();

          //this.saved_card_section =true;
          this.hideCard_div=false;
          this.hidecardmenu=false;
          this.hideshowpaynow=false;
          this.other_cards_display =false;
      },

      //no need
      show_card_form(){
        this.saved_card_tab1 = true;
        this.card_tab = false;
      },

      //no need
      display_toc(){
        this.show_terms_conditions = true;
        this.toc_dialog = true;
      },

      //no need
      select_card_icon(val){
          let first_digit = val.charAt(0);
          let second_digit = val.charAt(1);
          let first_two_digit = val.substring(0, 2)
          
          if(first_two_digit==34 || first_two_digit==37 ){

            // this.amexDeactive = false;
            // this.amexActive = true;
          
            // this.visaDeactive=true;
            // this.visaActive=false;

            // this.masterCardDeactive=true;
            // this.masterCardActive=false;

            // this.unionpayDeactive=true;
            // this.unionpayActive=false;

            // this.dinersDeactive=true;
            // this.dinersActive=false;

            // this.user_entered_card_name ="american-express";
            // this.isSelectedCard = true;

            this.snackbar = true;
            this.color = "red";
            this.timeout=2000;
            this.popup_text = "Please enter a valid card number";
            this.isSelectedCard = false;

          }

          if(first_digit== 3 && (second_digit ==0 || second_digit==6 || second_digit==8)){
              // this.dinersActive = true;
              // this.dinersDeactive = false;
              // this.user_entered_card_name = "dinners-club";
              // this.isSelectedCard = true;

              this.snackbar = true;
              this.color = "red";
              this.timeout=2000;
              this.popup_text = "Please enter a valid card number";
              this.isSelectedCard = false;
          }

          if(first_digit==4){
            this.visaDeactive=false;
            this.visaActive=true;

            this.masterCardDeactive=true;
            this.masterCardActive=false;

            this.amexDeactive=true;
            this.amexActive=false;

            this.unionpayDeactive=true;
            this.unionpayActive=false;

            this.dinersDeactive=true;
            this.dinersActive=false;

            this.user_entered_card_name="visa";
            this.isSelectedCard = true;
          }

          if((first_digit==5 && (second_digit>=1 && second_digit<=5)) || 
          (first_digit==2 && (second_digit>=2 && second_digit<=7))){

            this.masterCardDeactive=false;
            this.masterCardActive=true;

            this.visaDeactive=true;
            this.visaActive=false;

            this.amexDeactive=true;
            this.amexActive=false;

            this.unionpayDeactive=true;
            this.unionpayActive=false;

            this.dinersDeactive=true;
            this.dinersActive=false;

            this.user_entered_card_name="master";
            this.isSelectedCard = true;
          }


          // if(first_digit==6){
          //   this.unionpayDeactive=false;
          //   this.unionpayActive=true;
          //   this.user_entered_card_name="union-pay";
          //   this.isSelectedCard = true;
          // }

          if(first_digit==0 ||first_digit==1 ||first_digit==7 ||first_digit==8 ||first_digit==9||first_digit==6 ){
            this.snackbar = true;
            this.color = "red";
            this.timeout=2000;
            this.popup_text = "Please enter a valid card number";
            this.isSelectedCard = false;

            this.masterCardDeactive=false;
            this.masterCardActive=true;
            this.visaDeactive=false;
            this.visaActive=true;
            this.amexDeactive=false;
            this.amexActive=true;
            this.unionpayDeactive=false;
            this.unionpayActive=true;
            this.dinersDeactive=false;
            this.dinersActive=true;
          }
      },

      //no need
      transfer_number(selected_number){
        this.others.mobile_number = selected_number;
        document.getElementById("bks").focus();
      },
      
      //later
      delete_bks_num(delete_num,index){
        //console.log(delete_num);
        //console.log(index);
        //this.saved_bkash.splice(index, 1);
        var sp_token = this.$route.query.token;
        var mobile_no = delete_num;
        const options = {
            method: 'POST',
            url: this.base_url+'bkash-cancel-agreement',
            headers: {
              Authorization: 'Bearer '+ sp_token
            },
            data: {
              mobile_no
            },
            };
            axios.request(options).then(response =>{
              if(response.data.message==1){
                this.saved_bkash.splice(index, 1);
                // this.popup_dialog =false;
                // this.loading_tab=false;
                // this.loading_dialog=false;
              }
            }).catch(error =>{
              console.log(error);
            });

      },


      // saved-card-list methods

      input_cvv(card_number,card_name,index,cof_id){

          this.EnableSavedCardTDR(card_number);
          
          var total_card = this.cardList.length;
          this.cvv_pin.one = "";

          this.cvv_pin.saved_card_name = card_name;
          this.cvv_pin.saved_card_cof_id = cof_id;
          this.cvv_pin.card_mask_pan = card_number;

          if(total_card>0){

            for (let i = 0; i <total_card; i++) {

              if(index==i){
                  let before_id ="before_"+index;
                  let after_id ="after_"+index;
                  document.getElementById(before_id).style.display = "none";
                  document.getElementById(after_id).style.display = "block";
              }

              else{
                var before_id ="before_"+i;
                var after_id ="after_"+i;
                document.getElementById(before_id).style.display = "block";
                document.getElementById(after_id).style.display = "none";
              }

            } 

          }
         
      }, 
      //input_cvv method end

      CheckState(id){

        var input_cvv_number = this.cvv_pin.one;
        var total_card = this.cardList.length;

        if(total_card>0){

          for (let i = 0; i <total_card; i++) {

            if(id==i){
              if(input_cvv_number === ""){
                let before_id ="before_"+id;
                let after_id ="after_"+id;
                document.getElementById(before_id).style.display = "block";
                document.getElementById(after_id).style.display = "none";
              }
            }

            else{
              var before_id ="before_"+i;
              var after_id ="after_"+i;
              document.getElementById(before_id).style.display = "block";
              document.getElementById(after_id).style.display = "none";
            }


          } 

        }

      },

      getSavedCardList(){
            this.cardList = "";
            var customer_token = this.getCookie("token");

            if(customer_token !== ""){
              const options = {
                method: 'GET',
                url: this.customer_api_base_url+'show-card-info-list',
                headers: {
                    Authorization: 'Bearer '+customer_token
                },
              
              };

              axios.request(options).then(response =>{
                // When the response is success
                if(response.data.sp_code == '200'){
                  this.cardList = response.data.card_data;
                  this.isShowStaticPart = true;
                }

                // When no card data saved
                else if(response.data.sp_code == '204'){
                  this.show_cards(1);
                  this.isShowStaticPart = false;
                }

                // Authentication problem
                else if(response.data.sp_code == '1064'){
                  this.show_cards(1);
                  this.snackbar = true;
                  this.color = "error";
                  this.timeout = 2000;
                  this.popup_text = "Authentication Problem";
                  this.isShowStaticPart = false;
                }

                else{
                    this.show_cards(1);
                    this.isShowStaticPart = false;
                }
              
              }).catch(error =>{
                console.log(error);
              });

            }
      },

      deleteCardInfo(delete_id,index){

        this.deleteCardConfirmationDialog = true;
        this.deleteConfirmationCard = true;

        this.delete_card_id = delete_id;
        this.delete_card_ui_id = index;

      },

      DeleteCardYes(){
        var cof_id = (this.delete_card_id).toString();
        var cof_list_id = this.delete_card_ui_id;
        
        var customer_token = this.getCookie("token");

        if(customer_token !== ""){
          const options = {
            method: 'DELETE',
            url: this.customer_api_base_url+'delete-card',
            headers: {
                Authorization: 'Bearer '+customer_token
            },
            data: {
              cof_id,
            },
          
          };

          axios.request(options).then(response =>{
            // When the response is success
            if(response.data.sp_code == '200'){

              this.cardList.splice(cof_list_id,1);

              if(this.cardList.length>0){
                this.snackbar = true;
                this.color = "success";
                this.timeout = 2000;
                this.popup_text = response.data.message;
                this.deleteCardConfirmationDialog = false;
                this.deleteConfirmationCard = false;
              }

              else{
                this.show_cards(1);
              }

            }

            else if(response.data.sp_code == '401'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = response.data.message;
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }

            else if(response.data.sp_code == '424'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = response.data.message;
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }

            else if(response.data.sp_code == '208'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = response.data.message;
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }

            else if(response.data.sp_code == '104'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = response.data.message;
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }

            // Authentication problem
            else if(response.data.sp_code == '1064'){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = "Authentication Problem";
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }
            else{
              this.snackbar = true;
              this.color = "error";
              this.timeout = 2000;
              this.popup_text = "Something went wrong";
              this.deleteCardConfirmationDialog = false;
              this.deleteConfirmationCard = false;
            }
          }).catch(error =>{
            console.log(error);
          });

        }
      },

      DeleteCardNo(){
        this.deleteCardConfirmationDialog = false;
        this.deleteConfirmationCard = false;
        this.delete_card_id = "";
        this.delete_card_ui_id = "";
      },

      PayFromSaveCard(){

        var CryptoJSAesJson = {
        stringify: function (cipherParams) {
          var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
          if (cipherParams.iv) j.iv = cipherParams.iv.toString();
          if (cipherParams.salt) j.s = cipherParams.salt.toString();
          return JSON.stringify(j);
        },
        }
        var key = this.$route.query.order_id;
        var cvv_cvc1 = this.cvv_pin.one;
        var cvv_cvc = CryptoJS.AES.encrypt(JSON.stringify(cvv_cvc1),key,{format: CryptoJSAesJson}).toString();
        
        var orderid = this.$route.query.order_id;
        var store_id =  this.store_id;
        var engine_token = this.$route.query.token;
        var card_name = this.cvv_pin.saved_card_name;
        var cof_id = (this.cvv_pin.saved_card_cof_id).toString();
        var sp_login_status = this.getCookie("token");

        if(sp_login_status !== ""){
          var customer_token = this.getCookie("token");
          const options = {
            method: 'POST',
            url: this.customer_api_base_url+'cvv-pay',
            headers: {
              Authorization: 'Bearer '+ customer_token
            },
            data: {
              cvv_cvc,
              orderid,
              store_id,
              card_name,
              cof_id,
              engine_token,
            },
            
            };

          axios.request(options).then(response =>{
            if(response.data.original.sp_code == '200'){
              this.cvv_pin.one = "";
              this.cvv_pin.saved_card_name = "";
              this.cvv_pin.saved_card_cof_id = "";
              this.cvv_pin.card_mask_pan = "";

              window.location.href = response.data.original.url;
            }

            else if(response.data.original.sp_code == '424'){
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text = response.data.original.message;

            }
            else if(response.data.original.sp_code == '104'){
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text =  response.data.original.message;
 
            }

            else{
              this.snackbar = true;
              this.color = "red";
              this.timeout = 2000;
              this.popup_text = "Something went wrong";
 
            }

            }).catch(error =>{
              console.log(error);
              
            });  
        }
        else{
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Something wrong";
        }

      },


      onUpdate (payload) {
        this.resultsMobile = payload
      },

      ActiveAccountResendOtp(){

        var token = this.$route.query.token;
        var mobile_no = this.active_account_mobile;
         const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-resend-code',
          headers: {
            Authorization: 'Bearer '+token
          },
          data:{
            mobile_no
          },
          };

           axios.request(options).then(response =>{
            if(response.data.sp_code == '200'){
              this.snackbar = true;
              this.color = "success";
              this.timeout = 7000;
              this.popup_text = "A new OTP sent to " + this.active_account_mobile;
              this.dispaly_time_otp = true;
              this.display_resend_otp_text =false;
              this.showRemaining(3); 
            }
            else{
               this.snackbar = true;
               this.color = "red";
               this.popup_text = response.data.message;
            }

           
          }).catch(error =>{
             console.log(error);
          });
      },


      ForgotPasswordSendOTP()
      {
        //let isValid = this.$refs.form2.validate();
        let isValid = this.resultsMobile["isValid"];

        if(isValid){

        var countryCode = this.resultsMobile["countryCode"];
        var formattedNumber = this.resultsMobile["formattedNumber"];
       
        this.forgot_password_mobile = formattedNumber;
        var mobile_no = formattedNumber;
        var token_check = this.$route.query.token;
        
        const options = {
          method: 'POST',
          url: this.customer_api_base_url+'customer-forgot-pass',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no,
            countryCode
          }
        };

        axios.request(options).then(response =>{
        if(response.data.sp_code == '200'){
          this.forgot_password_modal = false;
          this.forgot_password_otp_modal = true;
          var inputed_mobile = formattedNumber;
          this.otp_send_text_msg = inputed_mobile;
          this.forgot_password_mobile = inputed_mobile;
        }

        else{
            this.snackbar = true;
            this.color = "red";
            this.popup_text = response.data.message;
        }
        
        }).catch(error =>{
            console.log(error);
        });

        }

        else{
          this.snackbar = true;
          this.color = "red";
          this.timeout = 2000;
          this.popup_text = "Mobile number is not valid";
        }
      },

      ForgotPasswordSubmitOTP(){
        let isValid = this.$refs.form_otp.validate();
        if(isValid){
          var token = this.$route.query.token;
          var verify_code = this.forgot_password_otp;
          //var mobile_no = this.forgot_password_mobile;
          var mobile_no = this.otp_send_text_msg;
         
          
            const options = {
            method: 'POST',
            url: this.customer_api_base_url+'customer-verify-account',
            headers: {
              Authorization: 'Bearer '+token
            },
            data: {
                verify_code,
                mobile_no
            },
            
          };

          axios.request(options).then(response =>{
            if(response.data.sp_code == '111'){
              this.forgot_password_otp_modal = false;
              this.forgot_password_modal = false;
              this.new_password_modal = true;
              this.reset_password_mobile = mobile_no;
            }
            else if(response.data.sp_code == '101'){
              this.snackbar = true;
              this.color = "red";
              this.timeout=3000;
              this.popup_text = "OTP does not matched";
              this.reset_password_mobile = mobile_no;
            }
            else if(response.data.sp_code =='103'){
              this.snackbar = true;
              this.color = "red";
              this.timeout = 200;
              this.popup_text = response.data.message;
              this.reset_password_mobile = mobile_no;
            }
            else{
              this.snackbar = true;
              this.color = "red";
              this.timeout = 200;
              this.popup_text = response.data.message;
              this.reset_password_mobile = mobile_no;
            }
          
          }).catch(error =>{
            console.log(error);
            this.reset_password_mobile = mobile_no;
          });
            
        }
      },


      // Card on File TDR start
      CheckCardOnFileTdr(){

        var is_tdr_enabled = this.is_add_commission;

        if(is_tdr_enabled==1){

          var card_number_with_spaces = this.editedItem.card_number;
          var card_number_without_space = card_number_with_spaces.replace(/\s/g, '');
          var bin = Number(card_number_without_space.slice(0, 6));
          
          if(bin.toString().length >= 6){
            var orderid = this.$route.query.order_id;
            var store_id = this.store_id;
            var token = this.$route.query.token;

              const options = {
                method: 'POST',
                url: this.base_url+'cof-get-tdr-charge',
                headers: {Authorization: 'Bearer '+token},
                data:{bin,orderid,store_id}
              };

              axios.request(options).then(response =>{
  
                if(response.data.sp_code=="200"){
                  this.additional_fees_amt = Number(response.data.additional_amount).toFixed(2);
                  this.payable_amt = Number(response.data.full_amount).toFixed(2);
                  this.tdr_visibility = this.is_add_commission;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 0;
                  this.cof_pay_now_btn_label = "Pay "+Number(response.data.full_amount).toFixed(2)+" BDT";
                }

                else{
                  //disable tdr
                  this.tdr_visibility= 0;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;
                  this.cof_pay_now_btn_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
                }
                
              }).catch(error =>{
                  this.tdr_visibility= 0;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;
                  this.cof_pay_now_btn_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
                  console.log(error);
              });
          }

          else{
            this.tdr_visibility= 0;
            this.tdr_visibility_pay_now_btn = 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.cof_pay_now_btn_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
          }
         
        }

        else{
          this.cof_pay_now_btn_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
        }
       
      },
      // Card on File TDR end

      EnableSavedCardTDR(card_number){
        var is_tdr_enabled = this.is_add_commission;

        if(is_tdr_enabled==1){

          var mask_card_number = card_number;
          var bin = Number(mask_card_number.slice(0, 6));
          
          if(bin.toString().length >= 6){
            var orderid = this.$route.query.order_id;
            var store_id = this.store_id;
            var token = this.$route.query.token;

              const options = {
                method: 'POST',
                url: this.base_url+'cof-get-tdr-charge',
                headers: {Authorization: 'Bearer '+token},
                data:{bin,orderid,store_id}
              };

              axios.request(options).then(response =>{
  
                if(response.data.sp_code=="200"){
                  this.additional_fees_amt = Number(response.data.additional_amount).toFixed(2);
                  this.payable_amt = Number(response.data.full_amount).toFixed(2);
                  this.tdr_visibility = this.is_add_commission;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 0;
                  this.saved_card_paynow_label = "Pay "+Number(response.data.full_amount).toFixed(2)+" BDT";
                }

                else{
                  //disable tdr
                  this.tdr_visibility= 0;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;
                  this.saved_card_paynow_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
                }
                
              }).catch(error =>{
                  this.tdr_visibility= 0;
                  this.tdr_visibility_pay_now_btn = 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;
                  this.saved_card_paynow_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
                  console.log(error);
              });
          }

          else{
            this.tdr_visibility= 0;
            this.tdr_visibility_pay_now_btn = 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.saved_card_paynow_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
          }
         
        }

        else{
          this.saved_card_paynow_label = "Pay "+Number(this.sp_amount).toFixed(2)+" BDT";
        }
      }

    },

}
</script>


<style scoped>

  /* Gateway hover and normal colors --start */
  .normal_img{
    cursor:pointer;
    border-bottom:4px solid #fff;
    border-radius:8px;
  }
  .hover_img{
    cursor:pointer;
    border-bottom:4px solid #0F9750;
    border-radius:8px;
  }
  /* Gateway hover and normal colors --end */

  .fastpay_div{
    margin-top:-10px !important;
    margin-left:2px !important;
    margin-right:2px !important;
    margin-bottom:9px !important;
  }
   .fastpaytext{
      margin-bottom: 0px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      font-weight: bold;
      font-size: 16x;
    }

  .page_bg{
    background-color: #f2f2f2 !important;
  }

  .text_style{
    font-size:13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    margin-right:-10px;
  }
  .style_signup_btn{
     margin-top: 3px;
     margin-left:-117px;
     font-size: 14px;
     text-transform: none;
     font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  }

  .forgot_btn{
    font-size: 13px;
    text-transform: none;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    margin-top: -10px;
    margin-left: 90px;
  }
  .style_login_btn{
     margin-top: 0px;
     margin-left: 0px;
     font-size: 14px;
     text-transform: none;
     font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  }
  .other_btn{
    margin-left:-5px !important;
    text-transform:none;
    font-size: 13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    font-weight: bold;
  }

  .saved_card_btn{
    text-transform:none;
    margin-left:-14px;
    font-size: 13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    font-weight: bold;
  }
 
  .emiDisplaytext{
    font-size:13px !important;
  }

    .paynow_div{
      margin-left:0px;
      margin-right:0px;
      min-height:70px;
      margin-top:14px;
    }

    .paynow_div_tdr{
      margin-left:25px;
      margin-right:25px;
      min-height:70px;
      margin-top:0px;
    }

    .main_div{
      margin-top:-30px;
     
    }
   
   .card_style{
        background-color:#ffffff;
        margin-top:100px !important;
    }

    .avatar_div{
      background-color: #fff;
      border-radius:15px 15px 0px 0px !important;
      height:56px;
    }

    .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
        margin-right:-7px !important;
      }

  
    .name_payment_amount{
        border-radius: 20px;
        background: #0F9750;
        padding: 10px;
    }

    .payment_to{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:5px;
    }
    

    .client_name_andLogout{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#000;
        margin-top:0px;
        text-align: right;
    }

    .logout_btn{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        text-transform: none;
        color:#0F9750 !important;
        padding:0px !important;
        height: 30px !important;
        margin-top:-2px;
    }
    .client_name{
        font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-18px;
    }

     .payment_amount{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-16px;
    }

    .order_id{
        font-size: 13px;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px;
    }

    .vertical_line{
        border-left: 2px dotted #0F9750;
        height:auto;
    }

   .pay_amount_main_div{
      background-color:#f2f2f2;
      /* height: 60px; */
      margin:5px;
      border: 2px solid #f2f2f2;
      padding:5px;
   
   }
   .name_logout{
      height: 30px;
      margin: 5px;
      padding:6px;
   }

   .border_left{
     border-left: 2px dotted #000000;
     height: 60px;
     margin-top: 22px;
   }

   .border_left2{
     border-left: 2px dotted #000000;
     height: 80px;
     margin-top: 22px;
   }

   .paynow_btn{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height:40px !important;
     text-transform: uppercase;
   }

   .paynow_btn_tdr{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height:40px !important;
     text-transform: uppercase;
   }

   .tabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0F9750;
     border-radius:5px;
     margin-right:3px; 
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }
   .tabs_text_style_mobile_banking{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0F9750;
     border-radius:5px;
     margin-right:3px; 
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }

   .lasttabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0a867b;
     border-radius:5px;
     margin-right:0px;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

   }

   .tos{
    font-size: 14px;
    margin-top: -4px;
    padding-left: 7px;
    padding-right: 0px;
    padding-bottom: 0px;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }


   .rowStyle{
     margin-top:-12px !important;
     margin-left:0px !important;
     margin-right:0px !important;
   }
  
   .containerStyle{
     margin-top:5px !important;
     min-height:250px !important;
   }
   .internetBankingSec{
     margin-top:5px !important;
     min-height:250px !important;
   }
   .mobileBankingSec{
     margin-top:5px !important;
     min-height:250px !important;
   }

   /* override default properties of v-tabs */
   .v-tabs-slider{
     color:#09512B !important;
     border-radius:10px;
   }
   
  @media only screen and (min-width: 370px) and (max-width: 404px) {
  
    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:2px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .lasttabs_text_style{
     font-size: 10px !important;
     border-radius:3px !important;
     margin-right:0px !important;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }
  .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  }
  .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
      }
  }

  
  @media only screen and (min-width: 360px) and (max-width: 370px) {

    .v-tab{
      letter-spacing: 0px !important;
    }
    
    .v-tabs-slider{
      width:95% !important;
    }
   
    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:1px !important;
      margin-left:-3px !important;
      
      }
      .lasttabs_text_style{
      font-size: 10px !important;
      border-radius:3px !important;
      margin-right:0px !important;
      margin-left:-5px !important;
    }
    .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }
    .order_id{
      font-size: 10px !important; 
    }

    .tos{
    font-size: 12px;
    margin-top: -12px;
    padding-left: 7px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-top: 3px solid #f3eeee;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }

  }

  @media only screen and (min-width: 350px) and (max-width: 360px) {
    
    .v-tab{
      letter-spacing: 0px !important;
    }
    
    .v-tabs-slider{
      width:90% !important;
    }
   
    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:1px !important;
      margin-left:-5px !important;
      
      }
      .lasttabs_text_style{
      font-size: 10px !important;
      border-radius:3px !important;
      margin-right:0px !important;
      margin-left:-5px !important;
    }
    .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }
    .order_id{
      font-size: 10px !important; 
    }

    .tos{
    font-size: 12px;
    margin-top: -12px;
    padding-left: 7px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-top: 3px solid #f3eeee;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }

   
  }

  @media only screen and (min-width: 320px) and (max-width: 350px) {
    
    .v-tab{
      letter-spacing: 0px !important;
    }
    
    .v-tabs-slider{
      width:90% !important;
    }
   
    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:1px !important;
      margin-left:-10px !important;
      
      }
      .lasttabs_text_style{
      font-size: 10px !important;
      border-radius:3px !important;
      margin-right:0px !important;
      margin-left:-5px !important;
    }
    .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }
    .order_id{
      font-size: 10px !important; 
    }

    .tos{
    font-size: 12px;
    margin-top: -12px;
    padding-left: 7px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-top: 3px solid #f3eeee;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }

   
  }

  @media only screen and (max-width: 422px) {
  .payment_to{
      font-size: 16px;
  }

  .client_name{
      font-size: 13px;
    }

    .payment_amount{
      font-size: 15px;
    } 

  .order_id{
      font-size: 11px; 
  }
  .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }


    .tdr_amount_label{
        font-size: 11px !important;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
    }
    .amount_label{
        font-size: 11px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        margin-left:5px !important;
    }

    .additional_fees{
       font-size: 11px !important;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px !important;
        
    }
    .tdrInfoBtn{
      font-size: 12px !important;
      margin-left: 5px !important;
      color:rgb(224, 205, 101) !important;
    }

    .border_left2{
     border-left: 2px dotted #000000;
     height: 60px;
     margin-top: 22px;
   }

  }

  /* TDR Design 2 start*/
     .tdr2{
        font-size: 11px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
        margin-left:0px !important;
        /* background-color: #fff !important; */
      }


      .tdr3{
        font-size: 11px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        /* margin-left:5px !important; */
      }


       .tdr4{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-16px;
    }

  /* TRD Design 2 end */


  /* TDR */
   .tdr_amount_label{
        font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
        /* background-color: #fff !important; */
    }
    .amount_label{
        font-size: 13px;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        margin-left:5px !important;
    }

    .additional_fees{
       font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px !important;
    }
    .tdrInfoBtn{
      font-size: 13px !important;
      margin-left: 10px;
      color:rgb(224, 205, 101) !important;
    }


    /* save-card-list css*/
     .FastPayContainer{
        border:1px solid #e5d7d7;
        height: 64px;
        background-color:#f2f2f2 !important;
        margin-left:10px !important;
        margin-right: 12px !important;
    }

    .listItems{
        padding-left: 10px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .listItems2{
        padding-left: 10px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-size: 18px;
        
    }

      .listItems3{
          padding-left: 10px !important;
          font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
          font-size: 18px;
      }

      .initialBtn{
          padding-left: 30px !important;
          font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      }
      .add_card_icon{
          margin-left: 17px !important;
          font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      }
      .cvv_style{
          font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin-top: 30px;
      }
      .pin_style{
          font-size:25px !important;
          color: black !important;
          text-align: center !important;
          font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif !important;
      }
      .centered-input >>> input {
        text-align: center
      }
      .emiDisplaytext{
      font-size:12px !important;
    }

    .cardPan{
      padding-left: 10px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      font-weight:bolder;
      font-size:16px !important;
      color:rgb(88, 89, 93) !important;
    }

    .NoValidCard{
      padding-left: 10px !important;
      font-size: 9px;
      color:#13bb13 !important;
    }

    .cardIdentifier{
      padding-left: 10px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      text-transform: capitalize;
      font-size:14px !important;
      color:#48494c !important;
    }

    .terms_and_services{
       font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
       font-size: 16px !important;
       color:#5d5a5a !important;
       text-align: justify !important;
       padding-top: 10px !important;
    }

    .terms_and_services_anchor{
       font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
       font-size: 16px !important;
    }

    #AnotherCardPay:hover{
        background-color:#fefefe;
      
    }
    

</style>


