module.exports = {
    // base_url:"https://engine.shurjopayment.com/api/",
    // image_base_url:"https://engine.shurjopayment.com/",
    // merchant_logo_image_base_url:"https://admin.shurjopayment.com/",
    // cancel_base_url:"https://engine.shurjopayment.com/",
    // customer_api_base_url:"https://engine.shurjopayment.com/customer-api/",

    /* Staging server */
    // base_url: "https://stagingapp.engine.shurjopayment.com/api/",
    // image_base_url: "https://stagingapp.engine.shurjopayment.com/",
    // merchant_logo_image_base_url: "https://stagingapp.admin.shurjopayment.com/",
    // cancel_base_url: "https://stagingapp.engine.shurjopayment.com/",
    // customer_api_base_url:"https://stagingapp.engine.shurjopayment.com/customer-api/",

    /* SandBox Dev server */
    base_url: "https://dev.engine.shurjopayment.com/api/",
    image_base_url: "https://dev.engine.shurjopayment.com/",
    merchant_logo_image_base_url: "https://dev.admin.shurjopayment.com/",
    cancel_base_url: "https://dev.engine.shurjopayment.com/",
    customer_api_base_url:"https://dev.engine.shurjopayment.com/customer-api/",
    
    /*Base URLs for Local server*/
    //base_url: "http://localhost/shurjopay-engine/api/",
    //image_base_url: "http://localhost/shurjopay-engine/",
    //merchant_logo_image_base_url: "http://localhost/shurjopay-engine/",
    //cancel_base_url: "http://localhost/shurjopay-engine/",
    //customer_api_base_url:"http://localhost/shurjopay-engine/customer-api/"
}
