<template>
    <div class="main_div">

      <v-card class="mx-auto" max-width="450" style="margin-top:136px !important;border-radius:15px">

        <!-- Snackbar div start -->

        <v-snackbar
            v-model="snackbar" :color="color" :timeout="timeout" :top="true" style="font-size:12px">
            {{popup_text}}

            <template v-slot:action="{ attrs }">
                <v-btn text color="#ffffff" v-bind="attrs" @click="snackbar = false" style="font-size:12px">Close</v-btn>
            </template>
        </v-snackbar>
        <!-- Snackbar div end -->

        <!-- avatar img div start -->
        <div class="avatar_div">
            <v-card-actions class="justify-center">
            <v-avatar size="100px" style="margin-top:-56px !important;" class="justify-center avatar_img" >
            <v-img
                :src="require('../components/../assets/bisc/bisc_logo.png')"
                alt="BISC logo"
                title="BISC logo"
                style="background-color:#fff;width:100px"
                contain
            ></v-img>
            </v-avatar>
            </v-card-actions>
        </div>
        <!-- avatar img div start -->

        <!-- Payment amount div start -->
        <div class="pay_amount_main_div">
            <v-container style="margin-top:-20px;">
            <v-row style="height:60px;margin-top:-10px;">
                <v-col md="6" sm="6" xs="12">
                <p class="payment_to">Payment to</p>
                <p class="client_name">BISC</p>

                </v-col>

                <v-col md="6" sm="6" xs="12" class="border_left">
                <p class="payment_amount">
                   <v-progress-circular
                    indeterminate
                    color="primary"
                    style="height: 20px; width: 20px;"
                    v-if="money_loader"
                    ></v-progress-circular>

                    {{trxn_amount}} &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{trxn_currency}}</span></p>
                <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{trxn_orderid}}</p>
                </v-col>
            </v-row>
            </v-container>
        </div>
        <!-- Payment amount div end -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        <!-- Main content div for small device start -->
        <div style="background-color:#fff;margin:5px">

            <v-card elevation="0">
                <v-container  grid-list-xl justify-space-around wrap fluid class="containerStyle">
                <v-row dense>
                    <v-col cols="12">
                        <v-card color="#fdfdfd" elevation="2">
                            <v-card-actions class="justify-center" style="height:80px;">
                                <v-avatar class="ma-3" tile style="height: auto; min-width: 125px; width: 125px;" >
                                    <v-hover v-slot:default="{ hover}">
                                        <v-img
                                        :src="require('../components/../assets/bisc/tap.png')"
                                        alt = "TAP Logo"
                                        max-width = "100%"
                                        :class=" hover ? 'hover_img': 'normal_img'"
                                        @click="InitiatePayment('tap')" 
                                        title="TAP Mobile Wallet"
                                        >
                                        <v-img
                                        :src="require('../components/../assets/bisc/tap.png')"
                                        v-if="hover"
                                        max-width = "100%"
                                        title="tap Mobile Wallet"
                                        ></v-img>

                                        </v-img>

                                    </v-hover>

                                </v-avatar>

                            </v-card-actions>

                            <v-card-text>
                                <v-card-subtitle style='color: #000;font-family: Calibri, Candara, Arial, sans-serif;font-size: 14px;text-align:justify; margin-top: -30px;'>
                                    <span style="font-weight:bold">1%</span> online service charge (<span style="font-weight:bold">Max 50 Taka</span>) will be added for mobile payment.
                                </v-card-subtitle>

                            </v-card-text>
                        </v-card>
                    </v-col> 

                   <v-col cols="12">
                        <v-card color="#fdfdfd" elevation="2">
                            <v-card-actions class="justify-center" style="height:80px;">
                                <v-avatar class="ma-3" tile style="height: auto; min-width: 125px; width: 125px;" >
                                    <v-hover v-slot:default="{ hover}">
                                        <v-img
                                        :src="require('../components/../assets/bisc/trust_bank.png')"
                                        alt = "Trust Bank Logo"
                                        max-width = "100%"
                                        :class=" hover ? 'hover_img': 'normal_img'"
                                        @click="InitiatePayment('tbl-visa')"
                                        title="Trust Bank Card"
                                        >
                                        <v-img
                                        :src="require('../components/../assets/bisc/trust_bank.png')"
                                        v-if="hover"
                                        max-width = "100%"
                                        title="Trust Bank Card"
                                        ></v-img>

                                        </v-img>

                                    </v-hover>

                                </v-avatar>

                            </v-card-actions>

                            <v-card-text>
                                 <v-card-subtitle style='color: #000;font-family: Calibri, Candara, Segoe,Arial, sans-serif;font-size: 14px;text-align:justify;margin-top: -30px;'>
                                All Q-Cash bank cards are accepted.
                                </v-card-subtitle>

                                <v-card-subtitle
                                style="color: rgb(0, 0, 0); font-family: Calibri, Candara, Segoe, Arial, sans-serif; font-size: 14px;margin-top: -26px;text-align:justify;"
                                >
                                Extra <span style="font-weight:bold">15 Taka</span> online service charge will be added for card payment.
                                </v-card-subtitle>

                            </v-card-text>

                        </v-card>
                    </v-col> 

                    <v-col cols="12" v-if="loading_tab">
                        <v-dialog v-model="loading_dialog" persistent width="385">
                            <v-card color="#257D3E" dark >
                                <v-card-text class="text-center">
                                    <p style="font-size:16px;font-weight:bold;color:#fff;margin-bottom:4px;padding-top:10px">
                                        {{loading_text}}
                                    </p>
                                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>

                </v-row>
                </v-container>
            </v-card>
        </div>
        <!-- Main content div for small device end -->

        <!-- Cancel Button Start -->
        <div style="margin-top:5px;padding-left:5px;padding-right:5px">
            <v-row justify="center">
            <v-col md="8" sm="12" xs="12">
                <p style="font-size:12px;margin-bottom:7px;text-align:center">
                    <span  @click="CanceledPayment" style="margin-left:2px;text-decoration: underline;color:#0f9750;cursor:pointer;">
                    Click Here</span> to cancel this transaction
                </p>
            </v-col>
            </v-row>
        </div>
        <!-- Cancel Button End -->

        <!-- footer section start -->
        <v-footer style="height:50px;margin-top:-10px;background-color:#f2f2f2 !important">
            <v-col md="6" sm="6" xs="6">
            <p 
                class="text-right"
                style="font-size:14px;color:#00592B;font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;"
            >Powered by</p>
            </v-col>

            <v-col md="6" sm="6" xs="6">
            <v-img
                :src="require('../components/../assets/logos/sp_footer_logo.png')"
                alt="ShurjoPay Logo"
                width="77px"
                style="margin-top:-18px;margin-left:-15px"
            >
            </v-img>
            </v-col>
        </v-footer>
        <!-- footer section end -->

      </v-card>

    </div>
</template>

<script>
import axios from "axios";
import SpMixin from "../mixins/SpMixin";
const pg_url = require('../../components/../pg_url');

export default {
    
  mixins: [SpMixin],
  
    props: {
        invoiceno:{
            default:"",
            type:String
        },
    },

    data: () => ({

        // Snackbar popup
        snackbar: false,
        color: "#0F9750",
        mode: "",
        timeout: 4000,
        popup_text: "",

        // Loding popup modal
        loading_tab:false,
        loading_dialog:false,
        loading_text :'',

        //Money loader
        money_loader:true,

        // Base URLs
        base_url: pg_url.base_url,
        image_base_url: pg_url.image_base_url,
        merchant_logo_image_base_url: pg_url.merchant_logo_image_base_url,
        cancel_base_url: pg_url.cancel_base_url,

        // Transaction data
        trxn_amount:"",
        trxn_currency:"",
        trxn_orderid:"",
        merchant_name:"",
        cancel_url:"",
        store_id:"",

    }),

    mounted() {
        this.trxn_orderid = this.$route.query.order_id;
        this.CheckOrderInfo();
    },

    methods:{

        InitiatePayment(card_name){

            var amount = this.trxn_amount;

            if(amount === ""){
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = "Please wait trxn amount is fetching.";
            }

            if(amount<10 && amount !== ""){
                this.snackbar = true;
                this.color = "red";
                this.timeout = 2000;
                this.popup_text = "Amount cannot be less than 10 TK";
            }
           
            if(amount>=10){
                this.loading_tab = true;
                this.loading_dialog = true;
                this.loading_text = "Please wait transaction is processing...";

                var orderid = this.$route.query.order_id;
                var invoiceno = this.invoiceno;
                var store_id = this.store_id;
                var sp_token = this.$route.query.token;
                
                const options = {
                    method: 'POST',
                    url: this.base_url+'only-card-pay',
                    headers: {
                        Authorization: 'Bearer '+sp_token
                    },
                    data: {amount,orderid,store_id,invoiceno,card_name},
                };

                axios.request(options).then(response =>{

                    if(response.data.message == 1){
                        this.loading_tab = false;
                        this.loading_dialog = false;
                        this.loading_text = '';
                        window.location.href = response.data.url;
                    }

                    else{
                        this.loading_tab = false;
                        this.loading_dialog = false;
                        this.loading_text = '';

                        this.snackbar = true;
                        this.color = "red";
                        this.timeout = 2000;
                        this.popup_text = "Something went wrong.";
                    }
                    

                }).catch(error =>{
                    this.loading_tab = false;
                    this.loading_dialog = false;
                    this.loading_text = '';

                    this.snackbar = true;
                    this.color = "red";
                    this.timeout = 2000;
                    this.popup_text = "Something went wrong.";
                    console.log(error);
                });
            }
        },

        CanceledPayment(){
            var order_id = this.$route.query.order_id;
            var sp_token = this.$route.query.token;
            const options = {
                method: 'POST',
                url: this.base_url+'canceled',
                headers: {
                    Authorization: 'Bearer '+ sp_token
                },
                data: {order_id},
            };

            axios.request(options).then(response =>{
            if(response.data.message==1){
                window.location.href = response.data.url;
            }
            else{
                this.snackbar = true;
                this.color = "error";
                this.timeout = 2000;
                this.popup_text = "Something went wrong...";
            }
            }).catch(error =>{
                this.snackbar = true;
                this.color = "error";
                this.timeout = 2000;
                this.popup_text = "Something went wrong...";
            });
        },

        CheckOrderInfo(){
            var orderid = this.$route.query.order_id;
            var sp_token = this.$route.query.token;
            const options = {
                method: 'GET',
                url: this.base_url+'merchant-config/'+orderid,
                headers: {
                    'Authorization': 'Bearer '+sp_token,
                    'Content-Type': 'application/json'
                },

            };

            axios.request(options).then(response =>{
                this.trxn_amount = parseFloat(response.data.gateway_info.request_amount).toFixed(2);
                this.trxn_currency = response.data.gateway_info.currency;
                this.store_id = response.data.gateway_info.store_id;
                this.cancel_url = "https://bisc.shurjoems.com/student/payment-cancel";
                this.money_loader = false;
            }).catch(error =>{
                this.money_loader = true;
                console.log(error);
            });
        },

    }

}
</script>

<style scoped>
    .normal_img{
        cursor:pointer;
        border-bottom:4px solid #fff;
        border-radius:8px;
    }

    .hover_img{
        cursor:pointer;
        border-bottom:4px solid #0F9750;
        border-radius:8px;
    }
  
    .page_bg{
        background-color: #f2f2f2 !important;
    }

    .main_div{
        margin-top:-30px;
        
    }
   
    .avatar_div{
      background-color: #fff;
      border-radius:15px 15px 0px 0px !important;
      height:56px;
    }

    .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
        margin-right:-7px !important;
    }


    .payment_to{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:5px;
    }
    
    .client_name{
        font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-18px;
    }

     .payment_amount{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-16px;
    }

    .order_id{
        font-size: 14px;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px;
    }
   .pay_amount_main_div{
      background-color:#f2f2f2;
      margin:5px;
      border: 2px solid #f2f2f2;
      padding:5px;
   
   }

   .border_left{
     border-left: 2px dotted #000000;
     height: 40px;
     margin-top: 22px;
   }

   .containerStyle{
     margin-top:5px !important;
     min-height:250px !important;
   }
  
  @media only screen and (min-width: 376px) and (max-width: 404px) {
  .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
      }
  }

  @media only screen and (min-width: 320px) and (max-width: 376px) {
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }
    .order_id{
      font-size: 10px !important; 
    }
   
  }

  @media only screen and (max-width: 422px) {
    .payment_to{
        font-size: 16px;
    }

    .client_name{
        font-size: 13px;
    }

    .payment_amount{
      font-size: 15px;
    } 

    .order_id{
        font-size: 11px; 
    }
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }

  }

</style>
